<div class="card p-3 h-full animation-duration-1000 animation-ease-out scalein animation-duration-1000 ">
    <div class="flex flex-row  justify-content-between align-items-center">
        <h5 class="text-base" #headline>{{widget.headline}}</h5>
        <div class="flex">
            <button pButton type="button" icon="pi pi-info-circle" [ngStyle]="{  color: getColor() }"
                class="p-button-rounded p-button-text p-button-plain" pTooltip="Hilfe"
                (click)="toogleShowInfo()"></button>
            <button pButton type="button" icon="pi pi-ellipsis-v" class="p-button-rounded p-button-text p-button-plain"
                (click)="op.toggle($event)"></button>
            <p-tieredMenu #op appendTo="body" [model]="menu" [popup]="true"></p-tieredMenu>

        </div>
    </div>
    <p-scrollPanel class="flex w-full h-full" [style]="{ width: '100%', height: 'calc(100% - 50px)' }">
        <ng-content></ng-content>
    </p-scrollPanel>
</div>
<p-dialog [header]="infoHeader" [(visible)]="visible" appendTo="body" styleClass="shadow-8" [style]="{width: '50vw'}">
    <p style="white-space: pre-line;">
        {{description}}
    </p>
    <div class="flex flex-row-reverse">
        <p-button label="Schließen" (click)="toogleShowInfo()" />

    </div>
</p-dialog>