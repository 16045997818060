import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ApiService } from '../api.service';
import { GridsterItem } from 'angular-gridster2';
import {
    dashboardComponents,
    getHeadline,
} from 'src/app/components/dashboard/dashboardComponentWrapper/dashboardComponentWrapper.component';
import { WebsiteService } from '../settings/website.service';
import { Website } from 'src/app/types/websiteDashboard.types';
import { Router } from '@angular/router';

/* allgemeine Presets */
export const overviewPresets = ['Überblick', 'Traffic Vergleich', 'Chancen & Trends'] as const;
export type OldPresets = (typeof overviewPresets)[number];
export type OverviewPresets = (typeof overviewPresets)[number];
export type TrafficVergleichPreset = (typeof overviewPresets)[number];

/* webseitenspezifische Presets */
export const websitePresets = [
    'Keywords',
    'Traffic Daten',
    'Optimierungen',
] as const;
export type WebsitePresets = (typeof websitePresets)[number];
export type WebsiteKeywords = (typeof websitePresets)[number];
export type websiteTrafficData = (typeof websitePresets)[number];
export type websiteOptimicer = (typeof websitePresets)[number];

// Speichert mehrer Dashboards
export interface Dashboards {
    overview: SubDashboards;
    [slug: string]: SubDashboards;
}

export interface SubDashboards {
    [dashboardName: string]: DashboardWidget[];
}

/* Standard Widget Aufbau */
export interface DashboardWidget extends GridsterItem {
    component: dashboardComponents;
    id: number;
    /* Die Headline wird allerdings automatisch gesetzt */
    headline: string;
    data?: any;
}

/* Erweiterung des Widgets */
export interface DashboardWidgetPreset extends DashboardWidget {
    presetSettings: {
        /* Wenn ein Widget webseitenspezifisch sein soll */
        forWebsite: boolean;
        /* Wenn ein Widget für alle eingepflegten Webseiten erstellt werden soll */
        forAllWebsites: boolean;
    };
}

@Injectable({
    providedIn: 'root',
})
export class GeneralDashboardService {
    private dashboardsSubject = new BehaviorSubject<Dashboards>({
        overview: {
            Übersicht: [],
        },
    });
    public dashboards$ = this.dashboardsSubject.asObservable();
    websites: Website[] = [];
    previousDashboard!: Dashboards;
    isInitLoad = true;

    constructor(
        private apiService: ApiService,
        private websiteService: WebsiteService,
        private router: Router
    ) {
        this.getDashboardFromDB();

        this.websiteService.websites$.subscribe(
            (websites) => (this.websites = websites)
        );
        this.dashboards$.subscribe((dashboards) => {
            if (
                this.previousDashboard &&
                JSON.stringify(this.previousDashboard) !==
                    JSON.stringify(dashboards)
            ) {
                // Save the dashboard
                if (!this.isInitLoad) {
                    this.saveDashboardInDB(JSON.stringify(dashboards));
                }
            }
            this.previousDashboard = JSON.parse(JSON.stringify(dashboards));

            this.isInitLoad = false;
        });
    }

    /* Widget hinzufügen */
    addWidget(slug: string, dashboardName: any, widget: DashboardWidget) {
        const dashboards = this.dashboardsSubject.getValue();
        dashboards[slug][dashboardName].push(widget);
        this.dashboardsSubject.next(dashboards);
    }

    /* Widget entfernen */
    removeWidget(
        dashboard: string,
        dashboardName: any,
        widget: DashboardWidget
    ) {
        const dashboards = this.dashboardsSubject.getValue();
        dashboards[dashboard][dashboardName] = dashboards[dashboard][
            dashboardName
        ].filter((w) => w.id !== widget.id);
        this.dashboardsSubject.next(dashboards);
    }

     /* Widget gegen ein Anderes austauschen */
    changeWidget(
        dashboard: string,
        dashboardName: any,
        widget: DashboardWidget
    ) {
        const dashboards = this.dashboardsSubject.getValue();
        const index = dashboards[dashboard][dashboardName].findIndex(
            (w) => w.id === widget.id
        );
        dashboards[dashboard][dashboardName][index] = widget;
        this.dashboardsSubject.next(dashboards);
    }

    /* Dashboard entfernen */
    deleteDashboard(slug: string, dashboardName: string) {
        const dashboards = this.dashboardsSubject.getValue();
        delete dashboards[slug][dashboardName];
        this.dashboardsSubject.next(dashboards);
    }

    /* Dashboard umbenennen */
    renameDashboard(slug: string, oldName: string, newName: string) {
        const dashboards = this.dashboardsSubject.getValue();
        dashboards[slug][newName] = dashboards[slug][oldName];
        delete dashboards[slug][oldName];
        this.dashboardsSubject.next(dashboards);
    }

     /* Dashboard hinzufügen */
    addWebsite(slug: string  ) {
        const dashboards = this.dashboardsSubject.getValue();
        dashboards[slug] = {
        };
        this.dashboardsSubject.next(dashboards);
    }


    /* Erstellung eines Dashboard durch Preset */
    async setUpDashboardWidgetsPreset(
        presetName: OverviewPresets | WebsitePresets,
        type: 'overview' | 'website',
        website?: Website
    ) {
        let id = 0;
        let widgets: DashboardWidget[] = [];
        const preset = await this.getPreset(type, presetName);
        for (const widget of preset) {
            if (widget.presetSettings.forAllWebsites) {
                for (const website of this.websites) {
                    widgets.push({
                        ...widget,
                        headline: getHeadline(
                            widget.component,
                            website,
                            widget.data?.ansicht
                        ),
                        id: id++,
                        data: {
                            ...widget.data,
                            domain: website.url,
                            website: {
                                ...website,
                                sichtbarkeitsindexVerlauf: undefined,
                            },
                        },
                    });
                }
            } else {
                widgets.push({
                    ...widget,
                    headline: getHeadline(
                        widget.component,
                        website,
                        widget.data?.ansicht
                    ),
                    id: id++,
                    data: widget.presetSettings.forWebsite
                        ? {
                              ...widget.data,
                              domain: website!.url,
                              website: {
                                  ...website,
                                  sichtbarkeitsindexVerlauf: undefined,
                              },
                          }
                        : {
                              ...widget.data,
                          },
                });
            }
        }
        return widgets;
    }

    /* Dashboard einer Webseite zuweisen */
    convertDashboardToWebsite(dashboard: DashboardWidget[], website: Website) {
        const newDashboard: DashboardWidget[] = [];
        for (const widget of dashboard) {
            newDashboard.push({
                ...widget,
                headline: getHeadline(widget.component, website),
                data: {
                    ...widget.data,
                    domain: website.url,
                    website: {
                        ...website,
                        sichtbarkeitsindexVerlauf: undefined,
                    },
                },
            });
        }
        return newDashboard;
    }

    /* Dashboard kopieren */
    pasteDashboard(
        slug: string,
        dashboardName: string,
        dashboard: DashboardWidget[],
        overwrite: boolean
    ) {
        const dashboards = this.dashboardsSubject.getValue();

        if (!overwrite && dashboards[slug][dashboardName] !== undefined) {
            dashboardName = dashboardName + ' (Kopie)';
            this.pasteDashboard(slug, dashboardName, dashboard, overwrite);
            return;
        }

        if (slug === 'overview') {
            dashboards[slug][dashboardName] = dashboard;
        } else {
            dashboards[slug][dashboardName] = this.convertDashboardToWebsite(
                dashboard,
                this.websites.find((w) => w.slug === slug)!
            );
        }

        this.dashboardsSubject.next(dashboards);
    }

    /* Preset holen */
    async getPreset(
        type: 'overview' | 'website',
        presetName: OverviewPresets | WebsitePresets
    ) {
        let preset: DashboardWidgetPreset[] = [];
        if (type === 'overview') {
            if (presetName === 'Überblick') {
                preset = (await import('./presets/overview/ueberblick')).preset;
            } else if (presetName === 'Traffic Vergleich') {

                preset = (await import('./presets/overview/trafficVergleich'))
                    .preset;
            } else if (presetName === 'Chancen & Trends') {
                preset = (await import('./presets/overview/Chancen'))
                    .preset;
            }
        } else {
            if (presetName === 'Keywords') {
                preset = (await import('./presets/website/keyword')).preset;
            } else if (presetName === 'Traffic Daten') {
                preset = (await import('./presets/website/traffic')).preset;
            } else if (presetName === 'Optimierungen') {
                preset = (await import('./presets/website/optimierungen'))
                    .preset;
            }
        }

        return preset;
    }

    async applyPreset(
        slug: 'overview' | string,
        dashboardName: string,
        presetName: OverviewPresets | WebsitePresets,
        website?: Website
    ) {
        const dashboards = this.dashboardsSubject.getValue();
        dashboards[slug][dashboardName] =
            await this.setUpDashboardWidgetsPreset(
                presetName,
                slug === 'overview' ? 'overview' : 'website',
                website
            );

        this.dashboardsSubject.next(dashboards);
        this.router
            .navigate(['/dashboard', slug, dashboardName])
            .then(() => window.location.reload());
    }

    async saveDashboardInDB(dashboards: string) {
        await this.apiService.sendPostRequest(
            'dashboard/saveDashboard',
            dashboards
        );
    }

    async getDashboardFromDB() {
        let result = await this.apiService.sendGetRequest<{
            id: string;
            dashboard: string;
        }>('dashboard/getDashboard');
        this.dashboardsSubject.next(JSON.parse(result.dashboard));
    }
}
