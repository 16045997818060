import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Website } from 'src/app/types/websiteDashboard.types';
import {
    DashboardWidget,
    GeneralDashboardService,
} from './generalDashboard.service';

// Speichert mehrer Dashboards
export interface ZwischenablageDashboard {
    name: string;
    type: 'overview' | 'website';
    dashboard: DashboardWidget[];
}

@Injectable({
    providedIn: 'root',
})
export class CopyDashboardService {
    private copiedDashboardSubject = new BehaviorSubject<
        ZwischenablageDashboard | undefined
    >(undefined);
    public copiedDashboard$ = this.copiedDashboardSubject.asObservable();
    websites: Website[] = [];

    constructor(private dashboardService: GeneralDashboardService) {}

    copyDashboard(dashboard: ZwischenablageDashboard) {
        this.copiedDashboardSubject.next(dashboard);
    }

    pasteDashboard(
        slug: string,
        dashboardName: string,
        overwrite: boolean = true
    ) {
        this.dashboardService.pasteDashboard(
            slug,
            dashboardName,
            this.copiedDashboardSubject.value?.dashboard!,
            overwrite
        );
    }
}
