<div class="layout-topbar">
    <button #menubutton class="p-link layout-menu-button layout-topbar-button"  (click)="layoutService.onMenuToggle()">
        <i class="pi pi-bars"></i>
    </button>
    <a class="layout-topbar-logo" routerLink="">
        <img src="https://www.internet-sicherheit.de/fileadmin/_processed_/2/9/csm_ifis-logo_977fe1fd11.png"
            alt="logo" />
        <span>SEO Cockpit</span>
    </a>

    <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button"
        (click)="layoutService.showProfileSidebar()">
        <i class="pi pi-ellipsis-v"></i>
    </button>

    <div #topbarmenu class="layout-topbar-menu">
        <div class="flex">
            <p-tieredMenu #menu [model]="items" [popup]="true" appendTo="body"></p-tieredMenu>
            <button #btn pButton class="p-button-rounded p-button-secondary p-button-text" (click)="menu.toggle($event)" icon="pi pi-user" ></button>
        </div>
    </div>
</div>