import { OnInit, ViewChild } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { Website } from '../types/websiteDashboard.types';
import { WebsiteService } from '../services/settings/website.service';
import {
    DashboardWidget,
    Dashboards,
    GeneralDashboardService,
} from '../services/dashboard/generalDashboard.service';
import { MenuItem } from 'primeng/api';

export interface MenuItemWithContextMenu extends MenuItem {
    slug: string | undefined;
    parent: true | undefined;
}

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
    model: any[] = [];
    websites: Website[] = [];
    dashboards?: Dashboards;

    copiedDashboard?: DashboardWidget[];
    copiedDashboardName: string = '';
    contextMenu: MenuItem[] = [];

    constructor(
        private layoutService: LayoutService,
        private websiteService: WebsiteService,
        private dashboardService: GeneralDashboardService
    ) {}

    buildMenu() {
        const model: any[] = [
            {
                label: 'Übersicht',
                items: [
                    {
                        label: 'Dashboard',
                        icon: 'pi pi-fw pi-home',
                        slug: 'overview',
                        parent: true,
                        routerLink: ['/dashboard/overview/Übersicht'],
                    },
                ],
            },

            {
                label: 'Webseiten',
                icon: 'pi pi-fw pi-briefcase',
                items: [],
            },
        ];

        if (this.websites.length > 0 && this.dashboards) {

            model[1].items = this.websites.map((website) => {
                if (!this.dashboards?.[website.slug]) {
                    return {
                        label: website.name,
                        icon: 'pi pi-fw pi-globe',
                        slug: website.slug,
                        parent: true,
                        items: [],
                    };
                }
                return {
                    label: website.name,
                    icon: 'pi pi-fw pi-globe',
                    slug: website.slug,
                    parent: true,
                    items: Object.keys(this.dashboards?.[website.slug]!).map(
                        (dashboardName) => {
                            return {
                                routerLink: [
                                    '/dashboard/' +
                                        website.slug +
                                        '/' +
                                        dashboardName,
                                ],
                                slug: website.slug,
                                label: dashboardName,
                                icon: 'pi pi-fw pi-th-large',
                            };
                        }
                    ),
                };
            });

            model[0].items![0].items = Object.keys(
                this.dashboards?.overview!
            ).map((dashboardName) => {
                return {
                    routerLink: ['/dashboard/overview' + '/' + dashboardName],
                    label: dashboardName,
                    slug: 'overview',
                    icon: 'pi pi-fw pi-th-large',
                };
            });
            if (JSON.stringify(this.model) !== JSON.stringify(model)) {
                this.model = model;
            }
        }
    }
/** Methode, die beim Aufbau der Komponente ausgeführt wird */
    ngOnInit() {
        this.websiteService.getWebsites();
        this.websiteService.websites$.subscribe((websites) => {
            this.websites = websites;
            this.buildMenu();
        });

        this.dashboardService.dashboards$.subscribe((dashboards) => {
            this.dashboards = dashboards;
            this.buildMenu();
        });
    }
}
