import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    //eventuell mit observables arbeiten

    private httpHeaders = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
        }),
    };

    constructor(private http: HttpClient) {
        const token = localStorage.getItem('token');
        if (token) {
            this.setToken(token);
        }
    }

    public setToken(token: string) {
        localStorage.setItem('token', token);
        this.httpHeaders = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            }),
        };
    }

    public async authenticate(username: string, password: string) {
        const url = 'auth/login';
        const data = { username, password };
        this.httpHeaders = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };
        let token = await this.sendPostRequest<{ token: string }>(url, data);
        localStorage.setItem('token', token.token);
        this.httpHeaders = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token.token,
            }),
        };
        return token;
    }

    public async pwVergessenEmailAnfordern(email: string) {
        const url = 'auth/passwortvergessen';
        const data = { email };
        this.httpHeaders = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };
        let emailSent: boolean = await this.sendPostRequest<boolean>(url, data);

        return emailSent;
    }

    public async pwAendern(neuesPasswort: string, token: string) {
        const url = 'auth/passwortaendern';
        const data = { neuesPasswort: neuesPasswort, token };
        this.httpHeaders = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };
        let pwChanged: boolean = await this.sendPostRequest<boolean>(url, data);

        return pwChanged;
    }

    /**
     * Send a POST request
     * @param url
     * @param data
     * @returns {Promise<Object>}
     */
    public async sendPostRequest<T>(url: string, data: any) {
        // setze URL aus environment.apiUrl und url
        url = environment.apiUrl + url;

        return await lastValueFrom(
            this.http.post<T>(url, data, this.httpHeaders).pipe(
                tap({
                    error: (error: any) => {
                        if (error.status === 500) {
                            // Handle 500
                        } else if (error.status === 400) {
                            // Handle 400
                        } else if (error.status === 401) {
                            // Handle 401
                        }
                    },
                })
            )
        );
    }

    /**
     * Send a GET request
     * @param url
     * @returns {Promise<Object>}
     */
    public async sendGetRequest<T>(url: string) {
        // setze URL aus environment.apiUrl und url
        url = environment.apiUrl + url;
        const res = await lastValueFrom(
            this.http.get<T>(url, this.httpHeaders).pipe(
                tap({
                    error: (error) => {
                        if (error.status === 500) {
                            //switch case besser
                            // Handle 500
                        } else if (error.status === 400) {
                            // Handle 400
                        } else if (error.status === 401) {
                            // Handle 401
                        }
                    },
                })
            )
        );
        return res;
    }

    /**
     * Send a GET request
     * @param url
     */
    public getRequest<T>(url: string) {
        // setze URL aus environment.apiUrl und url
        url = environment.apiUrl + url;
        const res = this.http.get<T>(url, this.httpHeaders).pipe(
            tap({
                error: (error) => {
                    if (error.status === 500) {
                        //switch case besser
                        // Handle 500
                    } else if (error.status === 400) {
                        // Handle 400
                    } else if (error.status === 401) {
                        // Handle 401
                    }
                },
            })
        );

        return res;
    }
}
