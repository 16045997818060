import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { ApiService } from './services/api.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
    constructor(
        private primengConfig: PrimeNGConfig,
        private apiService: ApiService,
        private router: Router
    ) {}

    ngOnInit() {
        this.primengConfig.ripple = true;
        this.setTheme();

        const token = localStorage.getItem('token');
        if (token) {
            this.apiService.setToken(token);
            this.checkJwtExpiry(token);
        } else {
            // navigate to login
            if (!this.router.url.includes('passwort-reset')) {
                // this.router.navigate(['/auth/login']);
            }
        }
    }

    setTheme() {
        // change link element with id theme-css
        const themeLink: HTMLLinkElement = document.getElementById(
            'theme-css'
        ) as HTMLLinkElement;
        let theme = localStorage.getItem('theme');
        if (theme) {
            // change  to assets/layout/styles/theme/vela-purple/theme.css
            themeLink.href = `assets/layout/styles/theme/${theme}/theme.css`;
        }
    }

    checkLoginState() {
        const jwtToken = localStorage.getItem('token');

        if (jwtToken) {
            this.checkJwtExpiry(jwtToken);
        }
    }
    checkJwtExpiry(token: string) {
        const parts = token.split('.');
        const payload = JSON.parse(atob(parts[1]));
        const expiry = payload.exp;

        const now = Date.now() / 1000; // aktuelle Zeit in Sekunden
        const delay = (expiry - now) * 1000; // Verzögerungszeit in Millisekunden

        if (expiry < now) {
            // Ablaufdatum ist bereits vergangen, Weiterleitung auslösen
            this.router.navigate(['/auth/login']);
            localStorage.removeItem('token');
        } else {
            // Ablaufdatum ist noch nicht erreicht
            setTimeout(() => {
                this.router.navigate(['/auth/login']);
                localStorage.removeItem('token');
            }, delay); // Timeout einrichten, um die Funktion erneut aufzurufen, wenn der JWT abläuft
        }
    }
}
