import { dashboardComponents } from './dashboardComponentWrapper.component';

interface WidgetDescription {
    /* Die Komponente, zu der der Tooltipp angezeigt werden soll */
    component: dashboardComponents;

    /* Die Überschrift aus dem Tooltip */
    headline: string;

    /* Der eigentliche Langtext zum Tooltipp */
    description: string;
}

/* Hier werden die Inhalte für die Tooltips eingepflegt */
export const descriptions: WidgetDescription[] = [
    /* Keyword-Platzierungs-Chart */
    {
        component: 'KeywordSichtbarkeitsChart',
        headline: 'Infos zum Keyword-Platzierungs-Chart',
        description: `Dieses Widget zeigt die SERP-Position der ausgewählten Webseite zu den Keywords im zeitlichen Verlauf an. Durch dieses Chart kann man Rückschlüsse auf seine Performance rings um das Themengebiet des Keywords machen.

                      Handlungsempfehlung: Sehen Sie eine stetige Steigung, dann verbessert sich die Performance zu dem Keyword stetig. Fällt die Position jedoch, so können Sie sehen, dass ein Eingriff erforderlich ist. Dieser Eingriff kann verschiedene Ausprägungen haben, wie z.B. Contentverbesserung/-erweiterung oder Optimierungen an der dazugehörigen URL.
        
                      SERP = Search Engine Result Page (deutsch: Suchmaschinen-Ergebnisseite)
                      Position: aktuelle Rankingposition bei Google

                      Hinweis: Bei fehlenden Datensätzen werden Lücken im Diagramm angezeigt. Dies liegt daran, dass Sistrix zu diesen Wochen keine Datensätze zur Verfügung stellt.

                      Falls Sie die Infos aus diesem Widget zu einer anderen Webseite angezeigt bekommen wollen, ist dies über "Optionen" -> "Webseite wechseln" möglich (nur im Übersicht-Dashboard).
        

                      Quelle der Daten: Sistrix`,
    },

    /* Keyword-Chancen-Widget */
    {
        component: 'keywordChance',
        headline: 'Infos zum Keyword-Chancen-Widget',
        description: `Dieses Widget zeigt Keywords an, zu denen diese Domain zwar bereits rankt, die Rankings aber nicht auf der ersten Seite zu finden sind. 
                      Per Klick auf die URL landen Sie auf der Landingpage, durch die das aktuelle Ranking entsteht.
                     
                      Handlungsempfehlungen: Optimieren Sie den Content auf Ihrer Webseite bezüglich der Keywords, die einen möglichst hohen Gain mit sich bringen. Mit etwas Aufwand lassen sich hier deutliche Trafficgewinne erzielen. Beachten Sie dabei sowohl die Platzierung als auch den Wettbewerb. Gerade wenn sich die Platzierung der Keywords kurz vor den 10. Platz befindet, ist die Chance groß, dass durch eine Optimierung der Sprung auf die erste Google-Seite geschafft werden kann. Dies ist vor allem rentabel, wenn der Traffic (Suchvolumen) eines Keywords hoch ist.

                      Wettbewerb: Indikator für die Wettbewerbsintensität für dieses Keyword im organischen Googleindex: 0 (gering) bis 100 (sehr stark).
                      Position: aktuelle Rankingposition bei Google. Wenn sich die Rankingposition gegenüber dem zuvor berechneten Wert verändert hat, wird hier angezeigt, um wie viele Positionen sie gestiegen/gesunken ist.
                      Gain: ein Index-Wert, welcher von 0 bis 100 geht und den möglichen Traffic-Zugewinn sowie die Stärke des Wettbewerbs berücksichtigt.
                      
                      Falls Sie die Infos aus diesem Widget zu einer anderen Webseite angezeigt bekommen wollen, ist dies über "Optionen" -> "Webseite wechseln" möglich (nur im Übersicht-Dashboard).
                      

                      Quelle der Daten: Sistrix`,
    },

    /* Keyword Traffic & Platzierungs Widget */
    {
        component: 'keywordRanking',
        headline: 'Infos zum Keyword Traffic & Platzierungs Widget',
        description: `In diesem Widget werden die im Menü hinzugefügten Keywords angezeigt, inklusive ihres Traffics und der Platzierung für die angegebene Webseite.

                      Handlungsempfehlungen: Wenn Ihre Webseite bei einem Keyword bereits sich auf dem ersten Platz befindet, performen Sie in dem Gebiet sehr gut. Wenn dies jedoch noch nicht der Fall ist, besteht noch eine Möglichkeit, um sich zu verbessern. Besonders zu beachten sind hier die Keywords, die sich entweder kurz vor Platz 10 (Google-SERP 1) oder vor Platz 1 befinden.
                      
                      Traffic: Anzahl der monatlichen Suchanfragen für das entsprechende Keyword im ausgewählten Land bei Google
                      Platzierung: aktuelle Rankingposition bei Google
                      SERP = Search Engine Result Page (deutsch: Suchmaschinen-Ergebnisseite)

                      Falls Sie die Infos aus diesem Widget zu einer anderen Webseite angezeigt bekommen wollen, ist dies über "Optionen" -> "Webseite wechseln" möglich (nur im Übersicht-Dashboard).


                      Quelle der Daten: Sistrix`,
    },

    /* Keyword-Ideen-Widget */
    {
        component: 'keywordIdeas',
        headline: 'Infos zum Keyword-Ideen-Widget',
        description: `Dieses Widget zeigt Keywords an, die potenziell zu dieser Domain passen würden. Unter den Ideen könnten Themen sein, die man auf seiner Seite integrieren könnten, welche bereits nahe an dem aktuellen Content sind.
        
                      Handlungsempfehlungen: Falls Sie eine passende Keyword-Idee in der Tabelle finden sollten, könnten es sich rentieren, Ihren Content, um die entsprechende Idee zu erweitern. Dies könnte zu Traffic-Gewinnen führen.
        
                      Wettbewerb: zeigt an, wie stark die Konkurrenz bei dem Keyword ist (0-100). Umso höher der Wert ist, desto mehr Aufwand muss aufgebracht werden, um hierbei gut zu ranken.
        
                      Falls Sie die Infos aus diesem Widget zu einer anderen Webseite angezeigt bekommen wollen, ist dies über "Optionen" -> "Webseite wechseln" möglich (nur im Übersicht-Dashboard).
                      

                      Quelle der Daten: Sistrix`,
    },

    /* Keyword-Traffic-Widget */
    {
        component: 'keywordtraffic',
        headline: 'Infos zum Keyword-Traffic-Widget',
        description: `In diesem Widget werden die im Menü hinzugefügten Keywords angezeigt, sortiert nach ihren Traffic. Hier raus lässt sich erkennen, welche Keywords für die Allgemeinheit hoher Relevanz sind und viel gesucht werden.
                      
                      Handlungsempfehlungen: Keywords mit einem Traffic haben ein größeres Potenzial dafür mehr Besucher anzulocken. Allerdings sollte das Keyword zu ihrem Content passen.

                      Traffic: Anzahl der monatlichen Suchanfragen für das entsprechende Keyword im ausgewählten Land bei Google


                      Quelle der Daten: Sistrix`,
    },

    /* onPageAnalytics-Widget */
    {
        component: 'onPageAnalytics',
        headline: 'Infos zur On Page Analyse',
        description: `Dieses Widget zeigt Fehler, Warnungen und Hinweise an, die auf der Webseite gefunden worden sind. 
                      Für einen Detailbericht (auch downloadbar als PDF) klicken Sie auf den Button in der jeweiligen Zeile.

                      Handlungsempfehlungen: Wenn vor allem die Fehler, aber auch die Warnungen entfernt werden, kann dies sich positiv auf das Google-Ranking und auf die generelle Nutzung auswirken. Bei den Hinweisen kann man nicht klar sagen, inwieweit diese vom Google Algorithmus beachtet werden. 

                      Falls Sie die Infos aus diesem Widget zu einer anderen Webseite angezeigt bekommen wollen, ist dies über "Optionen" -> "Webseite wechseln" möglich (nur im Übersicht-Dashboard).
                      

                      Quelle der Daten: Sistrix`,
    },

    /* Rankende-URLs-Widget */
    {
        component: 'rankendeUrls',
        headline: 'Infos zum Rankende-URLs-Widget',
        description: `In dieser Auswertung der aktuell rankenden URLs sehen Sie auf einen Blick, welche Inhalte gut von Google und den Nutzern angenommen werden und welche noch nicht. 
        
                      Handlungsempfehlungen: Achten Sie auf einen hohen Anteil von Top10-Rankings im Vergleich zu den Top100-Rankings, um erkennen zu können, wie gut der Inhalt der URL funktioniert. Sind viele Top10-Rankings vorhanden funktioniert der Content schon gut, wenn das nicht der Fall ist, sollte nachgebessert werden.

                      Top10-Rankings: ist bei X-Keywords unter den ersten 10 Google Suchergebnissen
                      Top100-Rankings: ist bei X-Keywords unter den ersten 100 Google Suchergebnissen	
                      Anteil an der Gesamtsichtbarkeit: Prozentanteil, wie viel diese URL zu der Gesamtsichtbarkeit beiträgt 
        
                      Falls Sie die Infos aus diesem Widget zu einer anderen Webseite angezeigt bekommen wollen, ist dies über "Optionen" -> "Webseite wechseln" möglich (nur im Übersicht-Dashboard).
                      

                      Quelle der Daten: Sistrix`,
    },

    /* top-rankende-URL-Widget */
    {
        component: 'bestRankendeUrl',
        headline: 'Infos zum top-rankende-URL-Widget',
        description: `In diesem Widget sehen Sie, welche URL aktuell am besten von der Webseite ranked. 

                      Handlungsempfehlungen: Der Inhalt dieser URL wird gut von Google und den Nutzern angenommen. Die anderen URLs der Domain könnte man vom Aufbau und Content ähnlich gestalten, um ein ähnlich positives Resultat zu erzielen. 

                      Top10-Rankings: ist bei X-Keywords unter den ersten 10 Google Suchergebnissen
                      Top100-Rankings: ist bei X-Keywords unter den ersten 100 Google Suchergebnissen	
                      Anteil an der Gesamtsichtbarkeit: Prozentanteil, wie viel diese URL zu der Gesamtsichtbarkeit beiträgt 

                      Falls Sie die Infos aus diesem Widget zu einer anderen Webseite angezeigt bekommen wollen, ist dies über "Optionen" -> "Webseite wechseln" möglich (nur im Übersicht-Dashboard).

                      
                      Quelle der Daten: Sistrix`,
    },

    /* PageSpeed-Insights-Widget */
    {
        component: 'pagespeed',
        headline: 'Infos zum PageSpeed-Insights-Widget',
        description: `Dieses Widget zeigt die Ergebnisse aus Google PageSpeed Insights an. Die Werte werden für die Mobile-Version angezeigt (änderbar unter Optionen), da der größte Teil der Besucher, so auf die Webseite gelangt.
                      Für einen Detailbericht klicken Sie auf den Button unten rechts.

                      Handlungsempfehlungen: Die erhaltenen Informationen können Ihnen dabei helfen die Ladezeiten Ihrer Website zu optimieren. Das Resultat aus einer Optimierung wäre eine positive Auswirkung auf das Crawling von Google und auf die allgemeine User Experience.

                      Falls Sie die Infos aus diesem Widget zu einer anderen Webseite angezeigt bekommen wollen, ist dies über "Optionen" -> "Webseite wechseln" möglich (nur im Übersicht-Dashboard).
                      
                      
                      Quelle der Daten: Google PageSpeed Insights`,
    },

    /* Website-Sicherbarkeitsindex-Widget */
    {
        component: 'sichtbarkeitsindex',
        headline: 'Infos zum Website-Sicherbarkeitsindex-Widget',
        description: `Dieses Widget zeigt den Sichtbarkeitsindex der ausgewählten Webseite an.

                      Handlungsempfehlungen: Der Sichtbarkeitsindex sollte stetig verbessert werden, um seine Webseite zu optimieren. Auf den Wert wirken sich alle Änderungen auf der Webseite aus.

                      Sichtbarkeitsindex: fasst in einem Wert die Sichtbarkeit einer Seite in den Google-Suchergebnissen zusammen. Mit ihm wird Erfolg (und Misserfolg) bei Google verlässlich und transparent messbar.

                      Falls Sie die Informationen aus diesem Widget zu einer anderen Webseite angezeigt bekommen wollen, ist dies über "Optionen" -> "Webseite wechseln" möglich (nur im Übersicht-Dashboard).
                      

                      Quelle der Daten: Sistrix`,
    },

    /* Webseiten-Sicherbarkeitsindex-Chart */
    {
        component: 'sichtbarkeitsindexchart',
        headline: 'Infos zum Webseiten-Sicherbarkeitsindex-Chart',
        description: `In diesem Widget werden die Sichtbarkeitsindizes der eingepflegten Webseiten im zeitlichen Verlauf angezeigt. Durch diese Ansicht kann die Entwicklung der Webseiten erkannt werden.
                      
                      Handlungsempfehlungen: Es sollte darauf geachtet werden, dass der Graph stetig steigend ist. Falls der Graph sinkt, sollte evaluiert werden, woran dies liegen kann (negative Änderungen an der Webseite im passenden Zeitraum etc.). Anschließend müssen dann Maßnahmen gegen diesen negativen Trend beschlossen und durchgeführt werden. 

                      Sichtbarkeitsindex: fasst in einem Wert die Sichtbarkeit einer Seite in den Google-Suchergebnissen zusammen. Mit ihm wird Erfolg (und Misserfolg) bei Google verlässlich und transparent messbar.
        
                      
                      Quelle der Daten: Sistrix`,
    },

    /* Top-Sicherbarkeitsindex-Widget */
    {
        component: 'topsichtbarkeitsindex',
        headline: 'Infos zum Top-Sicherbarkeitsindex-Widget',
        description: `Dieses Widget zeigt die Webseite, welche den höchsten Sichtbarkeitsindex von den eingepflegten Webseiten hat, an.

                      Handlungsempfehlungen: Bei der angezeigten Webseite, handelt es sich um die, die am besten von Google und von den Nutzern angenommen wird. Es wäre deshalb sinnvoll, sich bei Weiterentwicklung der anderen Webseiten, sich an dieser zu orientieren.  

                      Sichtbarkeitsindex: fasst in einem Wert die Sichtbarkeit einer Seite, in den Google-Suchergebnissen zusammen. Mit ihm wird Erfolg (und Misserfolg) bei Google verlässlich und transparent messbar.
                      

                      Quelle der Daten: Sistrix`,
    },

    /* Website-Traffic-Widget */
    {
        component: 'websiteTraffic',
        headline: 'Infos zum Website-Traffic-Widget',
        description: `Dieses Widget zeigt ihnen die Anzahl der Besucher innerhalb der letzten Woche der ausgewählten Webseite an. Die darunter eingeblendete Prozentzahl, zeigt Ihnen den prozentualen Gewinn/Verlust im Vergleich zu der davorigen Woche an.
                      Aus diesen KPIs lassen sich Rückschlüsse an dem Interesse an der ausgewählten Webseite ziehen.

                      Handlungsempfehlungen: Versuchen Sie durch eine positive Weiterentwicklung ihrer Webseite einen konstanten Gewinn an Besuchern zu erzielen.

                      Falls Sie die Infos aus diesem Widget zu einer anderen Webseite angezeigt bekommen wollen, ist dies über "Optionen" -> "Webseite wechseln" möglich (nur im Übersicht-Dashboard).
                      

                      Quelle der Daten: Matomo`,
    },

    /* Wettbewerber-Widget */
    {
        component: 'wettbewerberRanking',
        headline: 'Infos zum Wettbewerber-Widget',
        description: `Dieses Widget zeigt die Webseiten an, die eine Konkurrenz zu der angegebenen Webseite darstellen.
                      Ermittelt werden diese anhand von einem ähnlichen Top-100 Keyword-Sets.

                      Handlungsempfehlungen: Für eine Entwicklung Ihrer Webseite kann es sich rentieren zu recherchieren, welche Themen beim Konkurrenten erwähnt werden und ggf. gerade auch im Trend sind. Diese könnte man anschließend auch für sich gewinnen, um eine breitere Themenauswahl anzusprechen.

                      Übereinstimmung: Dieser Wert zeigt basierend auf dem Ranking-Keyword-Set die prozentuale Sichtbarkeit der Keyword-Wettbewerber an. Wird Domain A untersucht und hat Domain B z.B. einen Wert von 200 %, bedeutet das, dass Domain B etwa doppelt so viel Sichtbarkeit für die Keywords hat, bei denen Domain A Rankings in den Top-100 besitzt.

                      Falls Sie die Infos aus diesem Widget zu einer anderen Webseite angezeigt bekommen wollen, ist dies über "Optionen" -> "Webseite wechseln" möglich (nur im Übersicht-Dashboard)

                      Quelle der Daten: Sistrix`,
    },

    /* Visits-By-Website-Chart */
    {
        component: 'visitsByWebsite',
        headline: 'Infos zum Besuche pro Webseite-Chart',
        description: `Dieses Widget zeigt die Besuche pro Webseite innerhalb des letzten halben Jahres im Verlauf an. Im Vergleich zum Einzelne-Besucher-Chart werden hier auch die Klicks der wiederkehrenden Besucher getrackt. 
                      
                      Handlungsempfehlungen: Umso interessanter Sie den Content ihrer Webseite gestalten, desto höher weicht die Zahl der Besuche von den Besuchern ab. Dies kommt zustande dadurch, dass die Webseite den Besucher positiv in Erinnerung bleibt und er sie ggf. erneut aufruft. Versuchen Sie also durch eine positive Weiterentwicklung Ihrer Webseite einen konstanten Gewinn an Besuchen zu erzielen. 


                      Quelle der Daten: Matomo`,
    },

    /* Unique-Visitors-By-Website-Widget */
    {
        component: 'uniqueVisitorsByWebsite',
        headline: 'Infos zum einzelne Besucher pro Webseite-Widget',
        description: `Dieses Widget zeigt die einzelnen Besucher der Webseite innerhalb des letzten halben Jahres im Verlauf an.
                      
                      Handlungsempfehlungen: Versuchen Sie durch eine positive Weiterentwicklung Ihrer Webseite einen konstanten Gewinn an Besuchern zu erzielen.

                      Quelle der Daten: Matomo`,
    },

    /* Ausstiegsseiten-Titel-Widget */
    {
        component: 'ausstiegsseitenTitle',
        headline: 'Infos zum Ausstiegsseiten-Titel-Widget',
        description: `Dieses Widget zeigt Informationen über die Ausstiegsseiten (mit dem entsprechenden Titel) an. Hierdurch kann erkannt werden, an welchen Stellen viele Besucher die Webseite verlassen, da sie z.B. ggf. nicht den Content sehen, den sie sich erwünschen. 

                      Handlungsempfehlungen: Durch dieses Widget können Sie erkennen, an welcher Stelle Sie nachbessern müssen. Eine hohe Absprungrate kann durch unpassenden/schlechten Content entstehen, aber auch durch Fehler, schlechte Seitennavigation, unschöne Webseitendarstellung etc. 

                      Besuche: Anzahl der Besuche
                      Ausstiegsbesuche: Anzahl der Besuche, die auf dieser Seite beendet wurden
                      Ausstiegsrate: gibt an, welcher Prozentsatz der Seitenaufrufe die letzten in der Sitzung waren
                      Einstiegsbesuche: Anzahl der Besuche, die auf dieser Seite begonnen haben
                      Absprung-Besuche auf Einstiegsseite: Anzahl der Besuche, die auf dieser Seite begonnen haben und abgesprungen sind



                      Falls Sie die Infos aus diesem Widget zu einer anderen Webseite angezeigt bekommen wollen, ist dies über "Optionen" -> "Webseite wechseln" möglich (nur im Übersicht-Dashboard).

                      
                      Quelle der Daten: Matomo`,
    },

    /* Einstiegsseiten-Titel-Widget */
    {
        component: 'einstiegsseitenTitle',
        headline: 'Infos zum Einstiegsseiten-Titel-Widget',
        description: `Dieses Widget zeigt Informationen über die Einstiegsseiten (mit dem entsprechenden Titel) an. Hierdurch kann erkannt werden, dank welchem Content die Besucher auf die Webseite kommen. Wenn eine Seite häufig als Einstiegsseite genutzt wird, ist sie wahrscheinlich auch gut für Google optimiert und dient so als „Traffic-Magnet“.

                      Handlungsempfehlungen: Durch die Information, woher die Besucher kommen, können Sie gezielt an diesen Landingpages arbeiten, um den Besucher an der Webseite zu binden und ggf. auch Interesse an weiteren Themen zu erwecken. 

                      Benutzung:
                      Zeitraum: hier kann der Zeitraum angegeben werden, in dem die Daten ermittelt werden sollen
                      Spalten: hier können Spalten ein-/ausgeblendet werden, je nach Relevanz der Daten 

                      Einstiegsbesuche: generierte Aufrufe auf die URL
                      Absprungrate: für alle Sitzungen, die mit der Seite beginnen, ist die Absprungrate der Prozentsatz der Aufrufe, die der einzige Seitenaufruf der Sitzung waren
                      Durchschnittliche Zeit pro Seite: Durchschnittliche Zeit, die in Sekunden auf dieser Seite verbracht worden ist
                      Durchschnittliche Ladezeit: Durchschnittliche Ladezeit der Seite in Sekunden

        
                      Falls Sie die Infos aus diesem Widget zu einer anderen Webseite angezeigt bekommen wollen, ist dies über "Optionen" -> "Webseite wechseln" möglich (nur im Übersicht-Dashboard).
                      

                      Quelle der Daten: Matomo`,
    },

    {
        component: 'seitenTitle',
        headline: 'Infos zum Seiten-Titel-Widget',
        description: `Dieses Widget zeigt wichtige Kerninformationen über die Seiten mit dem Titel an.

                      Handlungsempfehlungen: Lassen Sie sich die Daten so anzeigen, dass eine präzise Analyse über eine URL möglich ist. Je nach angezeigtem Datensatz, können Sie sehen, wo man noch Optimierungen machen kann. 

                      Eingänge: generierte Aufrufe über die URL
                      Ausstiegsrate: gibt an, welcher Prozentsatz der Seitenaufrufe die letzten in der Sitzung waren
                      Absprungrate: für alle Sitzungen, die mit der Seite beginnen, ist die Absprungrate der Prozentsatz der Aufrufe, die der einzige Seitenaufruf der Sitzung waren

                      Falls Sie die Infos aus diesem Widget zu einer anderen Webseite angezeigt bekommen wollen, ist dies über "Optionen" -> "Webseite wechseln" möglich (nur im Übersicht-Dashboard).

                      
                      Quelle der Daten: Matomo`,
    },

    /* Einstiegsseiten-URL-Widget */
    {
        component: 'einstiegsseitenUrl',
        headline: 'Infos zum Einstiegsseiten-URL-Widget',
        description: `Dieses Widget zeigt Informationen über die Einstiegsseiten (mit der entsprechenden URL) an. Hierdurch kann erkannt werden, dank welchem Content die Besucher auf die Webseite kommen. Wenn eine Seite häufig als Einstiegsseite genutzt wird, ist sie wahrscheinlich auch gut für Google optimiert und dient so als „Traffic-Magnet“.

                      Handlungsempfehlungen: Durch die Information woher die Besucher kommen, können Sie gezielt an diesen Landingpages arbeiten, um den Besucher an der Webseite zu binden und ggf. auch Interesse an weiteren Themen zu erwecken. 

                      Einstiegsbesuche: generierte Aufrufe auf die URL
                      Absprungrate: für alle Sitzungen, die mit der Seite beginnen, ist die Absprungrate der Prozentsatz der Aufrufe, die der einzige Seitenaufruf der Sitzung waren
                      Durchschnittliche Zeit pro Seite: Durchschnittliche Zeit, die in Sekunden auf dieser Seite verbracht worden ist
                      Durchschnittliche Ladezeit: Durchschnittliche Ladezeit der Seite in Sekunden
        
                      Falls Sie die Infos aus diesem Widget zu einer anderen Webseite angezeigt bekommen wollen, ist dies über "Optionen" -> "Webseite wechseln" möglich (nur im Übersicht-Dashboard).
                      
                      
                      Quelle der Daten: Matomo`,
    },

    /* Ausstiegsseiten-URL-Widget */
    {
        component: 'ausstiegsseitenUrl',
        headline: 'Infos zum Ausstiegsseiten-URL-Widget',
        description: `Dieses Widget zeigt Informationen über die Ausstiegsseiten (mit der entsprechenden URL) an. Hierdurch kann erkannt werden, an welchen Stellen viele Besucher die Webseite verlassen, da sie z.B. ggf. nicht den Content sehen, den sie sich erwünschen. 

                      Handlungsempfehlungen: Durch dieses Widget können Sie erkennen, an welcher Stelle sie nachbessern müssen. Eine hohe Absprungrate kann durch unpassenden/schlechten Content entstehen, aber auch durch Fehler, schlechte Seitennavigation, unschöne Webseitendarstellung etc. 

                      Besuche: Anzahl der Besuche
                      Ausstiegsbesuche: Anzahl der Besuche, die auf dieser Seite beendet wurden
                      Ausstiegsrate: gibt an, welcher Prozentsatz der Seitenaufrufe die letzten in der Sitzung waren
                      Einstiegsbesuche: Anzahl der Besuche, die auf dieser Seite begonnen haben
                      Absprung-Besuche auf Einstiegsseite: Anzahl der Besuche, die auf dieser Seite begonnen haben und abgesprungen sind

                      Falls Sie die Infos aus diesem Widget zu einer anderen Webseite angezeigt bekommen wollen, ist dies über "Optionen" -> "Webseite wechseln" möglich (nur im Übersicht-Dashboard).

        
                      Quelle der Daten: Matomo`,
    },

    /* seiten-URL-Widget */
    {
        component: 'seitenUrl',
        headline: 'Infos zum Seiten-URL-Widget',
        description: `Dieses Widget zeigt wichtige Informationen über die Seiten mit der URL an.

                      Handlungsempfehlungen: Lassen Sie sich die Daten so anzeigen, dass eine präzise Analyse über eine URL möglich ist. Je nach angezeigte Datensatz, können sie sehen, wo man noch Optimierungen machen kann. 

                      Einstiegsbesuche: generierte Aufrufe auf die URL
                      Absprungrate: für alle Sitzungen, die mit der Seite beginnen, ist die Absprungrate der Prozentsatz der Aufrufe, die der einzige Seitenaufruf der Sitzung waren
                      Durchschnittliche Zeit pro Seite: Durchschnittliche Zeit die in Sekunden auf dieser Seite verbracht worden ist
                      Durchschnittliche Ladezeit: Durchschnittliche Ladezeit der Seite in Sekunden

                      Falls Sie die Infos aus diesem Widget zu einer anderen Webseite angezeigt bekommen wollen, ist dies über "Optionen" -> "Webseite wechseln" möglich (nur im Übersicht-Dashboard).
                      

                      Quelle der Daten: Matomo`,
    },

    /* Downloads-Widget */
    {
        component: 'downloads',
        headline: 'Infos zum Downloads-Widget',
        description: `In diesem Widget können Sie sehen, welche der von Ihnen bereitgestellten Dateien wie oft gedownloadet wird.

                      Handlungsempfehlungen: Durch die Kennzahl der Häufigkeit, können Sie überlegen, ob es sinnvoll wäre, die häufig gedownloadeten Dateien noch transparenter in Ihre Webseite einzubinden. Bei Dateien, bei denen Sie sich mehr Downloads erhofft haben, könnten Sie ebenfalls darüber nachdenken, ob die geringe Anzahl an einer schlechten Sichtbarkeit/Erreichbarkeit liegen könnte und ob dies durch eine Neuplatzierung ggf. geändert werden könnte.
        
                      Besuche: Anzahl der Downloads
                      Seitenaufrufe: Anzahl der Aufrufe dieser Seite

                      Falls Sie die Infos aus diesem Widget zu einer anderen Webseite angezeigt bekommen wollen, ist dies über "Optionen" -> "Webseite wechseln" möglich (nur im Übersicht-Dashboard).


                      Quelle der Daten: Matomo`,
    },

    /* Keyword-Trend-Widget */
    {
        component: 'trafficUndPositionTrends',
        headline: 'Infos zum Traffic und Plazierungs Trends-Widget',
        description: `Die Regression Slopes geben Hinweise auf mögliche Trends oder Muster zwischen Keywords und Traffic bzw. Ranking. Ein größerer Betrag des Koeffizienten deutet auf eine stärkere Beziehung hin. Es ist jedoch wichtig zu beachten, dass die Regression Slopes allein keine Kausalität bestimmen können und dass andere Faktoren ebenfalls eine Rolle spielen können.
    
                      Handlungsempfehlung: Wenn Sie einen Aufwärtstrend erkennen bei einem Keyword, können Sie sich überlegen, ob Sie Ihren Content diesbezüglich optimieren bzw. breiter aufstellen wollen.

                      Keyword: Das verwendete Keyword oder die Schlüsselwörter, die zur Beschreibung des Inhalts oder Themas einer Webseite oder eines Artikels dienen.
                      Aktueller Traffic: Die aktuelle Anzahl an Besuchen oder Seitenaufrufen, die mit dem jeweiligen Keyword generiert wurden.
                      Traffic Regression Slope: Die Regression Slope, auch Steigung genannt, zeigt die Stärke und Richtung der Beziehung zwischen dem Keyword und dem Traffic an. Ein positiver Wert bedeutet, dass eine Zunahme des Keywords mit einem Anstieg des Traffics einhergeht, während ein negativer Wert auf eine umgekehrte Beziehung hinweist.
                      Ranking Regression Slope: Die Ranking Regression Slope zeigt die Stärke und Richtung der Beziehung zwischen dem Keyword und dem Ranking in den Suchergebnissen. Ein positiver Wert bedeutet, dass eine Zunahme des Keywords mit einem besseren Ranking einhergeht, während ein negativer Wert auf eine umgekehrte Beziehung hinweist.
                      
                      
                      Quelle der Daten: Sistrix`,
    },

    /* Keywords aus der Google Search Console mit ihren Klicks und Impressionen */
    {
        component: 'gscKeywordsTable',
        headline: 'Infos zu Keywords Klicks und Impressionen ',
        description: `Die ausgewählte Komponente bietet einen Überblick über die Keywords, die von Google für Ihre Webseite während eines bestimmten Zeitraums erfasst wurden. Diese Keywords werden in einer Tabelle dargestellt und mit den dazugehörigen Metriken Klicks und Impressionen visualisiert.

                      Die Tabelle zeigt eine Liste der Keywords an, die von Google erkannt wurden und für die Ihre Webseite in den Suchergebnissen angezeigt wurde. Neben jedem Keyword werden die zugehörigen Metriken Klicks und Impressionen angezeigt, um Ihnen einen Einblick in die Performance jedes Keywords zu geben.
        
                      Per Klick auf das "+" innerhalb der Tabelle, fügen Sie das Keyword der entsprechenden Zeile, den Keywords in der Datenbank hinzu. 
                      Über den Datepicker kann der zu betrachtende Zeitraum ausgewählt werden.
                      
                      Handlungsempfehlungen: Durch die Visualisierung der Keywords, Klicks und Impressionen ermöglicht Ihnen die Komponente, die Leistung Ihrer Website für bestimmte Suchbegriffe zu analysieren. Sie können Trends und Muster erkennen, indem Sie die Performance der einzelnen Keywords vergleichen. Die Auswertung der Komponente bietet Ihnen wertvolle Informationen zur Optimierung Ihrer SEO-Strategie. Sie können Keywords identifizieren, die besonders gute Ergebnisse erzielen, und Ihre Inhalte entsprechend anpassen, um noch mehr Klicks und Impressionen zu generieren.

                      Klicks: Diese Metrik gibt die Gesamtzahl der Klicks auf Ihre Website für ein bestimmtes Keyword an. Sie zeigt, wie oft Benutzer auf Ihre Website gelangt sind, nachdem sie das Keyword in den Suchergebnissen gesehen haben.
                      Impressionen: Die Impressionen zeigen, wie oft Ihre Website in den Suchergebnissen für ein bestimmtes Keyword angezeigt wurde. Diese Metrik gibt an, wie oft Benutzer potenziell Ihre Website in den Suchergebnissen gesehen haben.
                      CTR (Klickrate): Die CTR gibt das Verhältnis von Klicks zu Impressionen an. Sie zeigt, wie oft Benutzer auf Ihre Website geklickt haben, nachdem sie sie in den Suchergebnissen gesehen haben. Eine höhere CTR bedeutet, dass Benutzer eher dazu neigen, auf Ihre Website zu klicken.

                      Falls Sie die Infos aus diesem Widget zu einer anderen Webseite angezeigt bekommen wollen, ist dies über "Optionen" -> "Webseite wechseln" möglich (nur im Übersicht-Dashboard).


                      Quelle der Daten: Google Search Console
        `,
    },

    /* Klicks und Impressionen einer Website für einen bestimmten Zeitraum */
    {
        component: 'gscTotal',
        headline: 'Infos zu Klicks und Impressionen Übersicht',
        description: `Die ausgewählte Komponente bietet Einblicke in die Leistung Ihrer Webseite in den Google-Suchergebnissen über einen bestimmten Zeitraum. Sie besteht aus den folgenden Metriken: Impressionen, Klickrate (CTR), Klicks und durchschnittliche Platzierung.

                      Handlungsempfehlungen: Die Auswertung der Komponente ermöglicht Ihnen die Bewertung des Erfolgs Ihrer SEO-Strategie und die Identifizierung von Verbesserungspotenzialen. Wenn beispielsweise die Impressionen hoch sind, aber die CTR niedrig ist, könnte dies darauf hindeuten, dass Ihre Titel und Beschreibungen in den Suchergebnissen nicht überzeugend genug sind. In einem solchen Fall können Sie Anpassungen vornehmen, um ansprechendere Snippets zu erstellen und die CTR zu erhöhen.

                      Impressionen: Diese Metrik gibt an, wie häufig Ihre Website in den Suchergebnissen von Google angezeigt wurde. Sie zeigt, wie oft Benutzer potenziell Ihre Website gesehen haben.
                      CTR (Klickrate): Die CTR gibt das Verhältnis von Klicks zu Impressionen an. Sie zeigt, wie oft Benutzer auf Ihre Website geklickt haben, nachdem sie sie in den Suchergebnissen gesehen haben. Eine höhere CTR bedeutet, dass Benutzer eher dazu neigen, auf Ihre Website zu klicken.
                      Klicks: Diese Metrik gibt die Gesamtzahl der Klicks auf Ihre Website aus den Suchergebnissen wieder. Sie zeigt, wie viele Benutzer tatsächlich auf Ihre Website gelangt sind.
                      Durchschnittliche Platzierung: Diese Metrik gibt die durchschnittliche Position Ihrer Website in den Suchergebnissen wieder. Sie zeigt, wie gut Ihre Website im Vergleich zu anderen Websites in den Suchergebnissen abschneidet. Je niedriger die durchschnittliche Position, desto besser.
                    
                      Die Komponente visualisiert nicht den Verlauf dieser Metriken, sondern bietet eine Momentaufnahme ihrer aktuellen Werte für den ausgewählten Zeitraum.
                      
                      
                      Falls Sie die Infos aus diesem Widget zu einer anderen Webseite angezeigt bekommen wollen, ist dies über "Optionen" -> "Webseite wechseln" möglich (nur im Übersicht-Dashboard).


                      Quelle der Daten: Google Search Console
        `,
    },

    /* Zugriffsquellen auf eine Website */
    {
        component: 'channeltype',
        headline: 'Infos zum Zugriffsquellen Chart',
        description: `Dieses Widget zeigt Ihnen alle Quellen an, von denen auf Ihre Webseite zugegriffen wird. Hierdurch können Sie erkennen, welche Kanäle gut funktionieren und welche noch optimiert werden müssen. In dem Widget wird immer der erzeugte Traffic über den Kanal angezeigt, daneben der prozentuale Anteil vom gesamten Traffic der Webseite und die prozentuale Veränderung der Zugriffe im Vergleich zur vorherigen Woche.
                      
                      Handlungsempfehlungen: Wenn Sie erkennen, dass ein Kanal noch keinen bzw. wenig Traffic erzeugt, dann können Sie überlegen, ob es sinnvoll wäre, ihr Engagement diesbezüglich zu verstärken. So könnten Sie durch eine bessere Ausnutzung von verschiedenen Kanälen einen gezielten Traffic-Gewinn erzeugen.
              
                      Falls Sie die Infos aus diesem Widget zu einer anderen Webseite angezeigt bekommen wollen, ist dies über "Optionen" -> "Webseite wechseln" möglich (nur im Übersicht-Dashboard).


                      Quelle der Daten: Matomo`,
    },

    /* Klicks und Impressionen einer Website im Verlauf */
    {
        component: 'gscImpressionCtrClickChart',
        headline: 'Infos zum Klicks und Impressionen im Verlauf Chart',
        description: `Das Diagramm zeigt die Entwicklung der Impressionen, der Klickrate (CTR) und der Klicks der Google-Suchergebnisse über einen ausgewählten Zeitraum. Die Impressionen geben an, wie oft Ihre Webseite in den Suchergebnissen von Google angezeigt wurde. Die CTR gibt das Verhältnis von Klicks zu Impressionen an und zeigt, wie oft Benutzer auf Ihre Webseite geklickt haben, nachdem sie sie in den Suchergebnissen gesehen haben. Die Klicks geben die Gesamtzahl der Klicks auf Ihre Webseite aus den Suchergebnissen wieder.

                      Das Diagramm veranschaulicht den Verlauf dieser Metriken im Zeitverlauf und kann Ihnen dabei helfen, Trends und Muster zu erkennen. Wenn die Impressionen steigen, deutet dies darauf hin, dass Ihre Webseite in den Suchergebnissen häufiger angezeigt wird. Eine steigende CTR zeigt, dass Benutzer eher dazu neigen, auf Ihre Webseite zu klicken, wenn sie sie sehen. Ein Anstieg der Klicks bedeutet, dass mehr Benutzer auf Ihre Webseite gelangen.
        
                      Handlungsempfehlungen: Das Diagramm kann Ihnen helfen, den Erfolg Ihrer SEO-Strategie zu bewerten und Verbesserungen vorzunehmen. Wenn beispielsweise die Impressionen hoch sind, aber die CTR niedrig ist, könnte es bedeuten, dass Ihre Titel und Beschreibungen in den Suchergebnissen nicht überzeugend genug sind. In diesem Fall könnten Sie Änderungen vornehmen, um ansprechendere Snippets zu erstellen und die CTR zu erhöhen.
               
                      Falls Sie die Infos aus diesem Widget zu einer anderen Webseite angezeigt bekommen wollen, ist dies über "Optionen" -> "Webseite wechseln" möglich (nur im Übersicht-Dashboard).


                      Quelle der Daten: Google Search Console`,
    },
];
