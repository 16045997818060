import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Website } from 'src/app/types/websiteDashboard.types';
import { ApiService } from '../api.service';

@Injectable({
    providedIn: 'root',
})
export class WebsiteService {
    public websiteSubject = new BehaviorSubject<Website[]>([]);
    public websites$ = this.websiteSubject.asObservable();

    constructor(private apiService: ApiService) {}

    getWebsites(): void {
        this.apiService
            .getRequest<Website[]>('settings/websites')
            .subscribe((websites) => this.websiteSubject.next(websites));
    }

    async saveWebsite(website: Website) {
        await this.apiService.sendPostRequest<Website[]>(
            'settings/websites',
            website
        );
        this.getWebsites();
    }

    async deleteWebsite(website: Website) {
        await this.apiService.sendPostRequest<Website>(
            'settings/deleteWebsite',
            website
        );
        this.getWebsites();
    }
}
