import { Component, ElementRef, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from './service/app.layout.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
})
export class AppTopBarComponent {
    items!: MenuItem[];

    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

    @ViewChild('topbarmenu') menu!: ElementRef;

    constructor(public layoutService: LayoutService) {}
/** Methode, die beim Aufbau der Komponente ausgeführt wird */
    ngOnInit() {
        this.items = [
            {
                label: 'Optionen',
                icon: 'pi pi-fw pi-cog',
                items: [
                    {
                        label: 'Webseiten',
                        icon: 'pi pi-fw pi-globe',
                        routerLink: ['/settings/websites'],
                    },
                    {
                        label: 'User',
                        icon: 'pi pi-fw pi-user',
                        routerLink: ['/settings/users'],
                    },
                    {
                        label: 'Keywords',
                        icon: 'pi pi-fw pi-tag',
                        routerLink: ['/settings/keyword'],
                    },
                    // {
                    //     label: 'Wettbewerber',
                    //     icon: 'pi pi-fw pi-users',
                    //     routerLink: ['/settings/wettbewerber'],
                    // },
                ],
            },
            /* {
                label: 'Authentifizierung',
                icon: 'pi pi-fw pi-sign-in',
                items: [
                    {
                        label: 'Login',
                        icon: 'pi pi-fw pi-sign-in',
                        routerLink: ['/auth/login'],
                    },
                    {
                        label: 'Error',
                        icon: 'pi pi-fw pi-times-circle',
                        routerLink: ['/auth/error'],
                    },
                    {
                        label: 'Access Denied',
                        icon: 'pi pi-fw pi-lock',
                        routerLink: ['/auth/access'],
                    },
                ],
            }, */
            {
                separator: true,
            },
            {
                label: 'Dark-Theme Toogle',
                icon: 'pi pi-fw pi-moon',
                command: () => {
                    // change link element with id theme-css
                    const themeLink: HTMLLinkElement = document.getElementById(
                        'theme-css'
                    ) as HTMLLinkElement;
                    if (themeLink.href.includes('light')) {
                        // change  to assets/layout/styles/theme/vela-purple/theme.css
                        themeLink.href = 'assets/layout/styles/theme/vela-purple/theme.css';
                        localStorage.setItem('theme', 'vela-purple');
                        window.location.reload();
                    } else {
                         // change to assets/layout/styles/theme/lara-light-indigo/theme.css
                         themeLink.href = 'assets/layout/styles/theme/lara-light-indigo/theme.css';
                            localStorage.setItem('theme', 'lara-light-indigo');
                        window.location.reload();
4
                    }


                },
            },
            {
                separator: true,
            },
            {
                label: 'Log-out',
                icon: 'pi pi-fw pi-sign-out',
                command: () => {
                    localStorage.removeItem('token');
                    window.location.reload();
                },
            },
        ];
    }
}
