export const generateColorFromString = (str: string, helligkeit = 0) => {
    // Konvertieren des Strings in einen Hash-Code
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    // Erzeugen der helleren Farbe aus dem Hash-Code
    let color = '#';
    for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xff;
        value = Math.min(255, value + helligkeit); // erhöhe den Farbwert um 40
        color += value.toString(16).padStart(2, '0');
    }

    return color;
};
