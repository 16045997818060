import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { MenuItem } from 'primeng/api';
import {
    DashboardWidget,
    GeneralDashboardService,
} from 'src/app/services/dashboard/generalDashboard.service';
import { WebsiteService } from 'src/app/services/settings/website.service';
import { Website } from 'src/app/types/websiteDashboard.types';
import { findNextFreeNumber } from 'src/app/utils/findNextFreeNumber';
import { descriptions } from './WidgetDescription';
import { generateColorFromString } from 'src/app/utils/generateColourFromString';

export type dashboardComponents =
    | 'sichtbarkeitsindex'
    | 'sichtbarkeitsindexchart'
    | 'topsichtbarkeitsindex'
    | 'keywordRanking'
    | 'KeywordSichtbarkeitsChart'
    | 'keywordtraffic'
    | 'pagespeed'
    | 'wettbewerberRanking'
    | 'keywordChance'
    | 'onPageAnalytics'
    | 'rankendeUrls'
    | 'bestRankendeUrl'
    | 'visitsByWebsite'
    | 'websiteTraffic'
    | 'channeltype'
    | 'keywordIdeas'
    | 'ausstiegsseitenTitle'
    | 'einstiegsseitenTitle'
    | 'seitenTitle'
    | 'einstiegsseitenUrl'
    | 'ausstiegsseitenUrl'
    | 'seitenUrl'
    | 'websiteTraffic'
    | 'downloads'
    | 'trafficUndPositionTrends'
    | 'uniqueVisitorsByWebsite'
    | 'gscImpressionCtrClickChart'
    | 'gscKeywordsTable'
    | 'gscTotal';

@Component({
    selector: 'app-dashboardComponentWrapper',
    templateUrl: './dashboardComponentWrapper.component.html',
    styleUrls: ['./dashboardComponentWrapper.component.scss'],
})
export class DashboardComponentWrapperComponent implements OnInit, OnChanges {
    @Input() type!: dashboardComponents;
    @Input() widget!: DashboardWidget;
    @Input() dashboardType!: 'dashboard' | 'website';
    @Input() dashboardName!: string;
    @Input() website?: Website | undefined;

    @Input() widgetWebsite: Website | undefined;

    allWidgets: DashboardWidget[] = [];
    websites: Website[] = [];

    infoHeader: string = '';
    description: string = '';
    visible: boolean = false;

    menu: MenuItem[] = [];

    constructor(
        private websiteService: WebsiteService,
        private dashboardService: GeneralDashboardService
    ) {}
/** Methode, die beim Aufbau der Komponente ausgeführt wird */
    ngOnInit() {
        this.websiteService.websites$.subscribe((websites) => {
            this.websites = websites;
            this.buildMenu();
        });

        this.description =
            descriptions.find((d) => d.component === this.widget.component)
                ?.description || 'Keine Beschreibung vorhanden';

        this.infoHeader =
            descriptions.find((d) => d.component === this.widget.component)
                ?.headline || 'Keine Beschreibung vorhanden';

        this.dashboardService.dashboards$.subscribe((widgets) => {
            this.allWidgets =
                widgets[this.website?.slug || 'overview'][this.dashboardName];
        });
    }

    toogleShowInfo() {
        if (this.visible) this.visible = false;
        else this.visible = true;
    }

    buildMenu() {
        const initMenu: MenuItem[] = [
            /* hier müssen die Widgets eingefügt werden, zu welchen gewechselt werden kann im Widget-Menü */
            {
                label: 'Widget wechseln',
                icon: 'pi pi-fw pi-undo',
                tooltip: 'dashboard,website',
                items: [
                    {
                        label: 'Keywords',
                        tooltip: 'dashboard,website',
                        items: [
                            {
                                label: 'Keyword Traffic & Platzierung',
                                icon: 'pi pi-fw pi-sort-alt',
                                tooltip: 'dashboard,website',

                                disabled:
                                    this.widget?.component === 'keywordRanking',
                                command: () => {
                                    this.dashboardService.changeWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            ...this.widget,
                                            component: 'keywordRanking',
                                            data: {
                                                website: this.getWebsite(),
                                            },
                                            headline: getHeadline(
                                                'keywordRanking',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                            {
                                label: 'Keyword-Platzierungs-Verlauf',
                                icon: 'pi pi-fw pi-chart-line',
                                tooltip: 'dashboard,website',
                                disabled:
                                    this.widget?.component ===
                                    'KeywordSichtbarkeitsChart',
                                command: () => {
                                    this.dashboardService.changeWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            ...this.widget,
                                            component:
                                                'KeywordSichtbarkeitsChart',
                                            data: {
                                                website: this.getWebsite(),
                                            },
                                            headline: getHeadline(
                                                'KeywordSichtbarkeitsChart',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                            {
                                label: 'Keyword Trend Analyse',
                                icon: 'pi pi-fw pi-arrow-up-right',
                                tooltip: 'dashboard,website',
                                disabled:
                                    this.widget?.component ===
                                    'trafficUndPositionTrends',
                                command: () => {
                                    const newWidget: DashboardWidget = {
                                        ...this.widget,
                                        component: 'trafficUndPositionTrends',
                                        headline: getHeadline(
                                            'trafficUndPositionTrends',
                                            this.getWebsite()
                                        ),
                                    };
                                    newWidget.data = {
                                        domain: this.website
                                            ? this.website.url
                                            : null,
                                    };
                                    this.dashboardService.changeWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        newWidget
                                    );
                                },
                            },
                            {
                                label: 'Keywords mit höchsten Traffic',
                                icon: 'pi pi-fw pi-star',
                                tooltip: 'dashboard',

                                disabled:
                                    this.widget?.component === 'keywordtraffic',
                                command: () => {
                                    this.dashboardService.changeWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            ...this.widget,
                                            component: 'keywordtraffic',
                                            data: {},
                                            headline:
                                                getHeadline('keywordtraffic'),
                                        }
                                    );
                                },
                            },
                            {
                                label: 'Keyword-Chancen',
                                icon: 'pi pi-fw pi-caret-up',
                                tooltip: 'dashboard,website',

                                disabled:
                                    this.widget?.component === 'keywordChance',
                                command: () => {
                                    this.dashboardService.changeWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            ...this.widget,
                                            component: 'keywordChance',
                                            data: {
                                                website: this.getWebsite(),
                                            },
                                            headline: getHeadline(
                                                'keywordChance',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                            {
                                label: 'Keyword-Ideen',
                                icon: 'pi pi-fw pi-info',
                                tooltip: 'dashboard,website',

                                disabled:
                                    this.widget?.component === 'keywordIdeas',
                                command: () => {
                                    this.dashboardService.changeWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            ...this.widget,
                                            component: 'keywordIdeas',
                                            data: {
                                                website: this.getWebsite(),
                                            },
                                            headline: getHeadline(
                                                'keywordIdeas',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                            {
                                label: 'Keyword Klicks und Impressionen',
                                icon: 'pi pi-fw pi-search',
                                tooltip: 'dashboard,website',
                                disabled:
                                    this.widget?.component ===
                                    'gscKeywordsTable',
                                command: () => {
                                    this.dashboardService.changeWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            ...this.widget,
                                            component: 'gscKeywordsTable',
                                            data: {
                                                website: this.getWebsite(),
                                            },
                                            headline: getHeadline(
                                                'gscKeywordsTable',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                        ],
                    },
                    {
                        label: 'Sichtbarkeit',
                        tooltip: 'dashboard,website',
                        items: [
                            {
                                label: 'Top Sichtbarkeitsindex',
                                icon: 'pi pi-fw pi-star',
                                tooltip: 'dashboard',
                                disabled:
                                    this.widget?.component ===
                                    'topsichtbarkeitsindex',
                                command: () => {
                                    this.dashboardService.changeWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            ...this.widget,
                                            component: 'topsichtbarkeitsindex',
                                            data: {},
                                            headline: getHeadline(
                                                'topsichtbarkeitsindex'
                                            ),
                                        }
                                    );
                                },
                            },
                            {
                                label: 'Webseiten Sichtbarkeitsindex',
                                icon: 'pi pi-fw pi-eye',
                                tooltip: 'dashboard,website',
                                disabled:
                                    this.widget?.component ===
                                    'sichtbarkeitsindex',
                                command: () => {
                                    const newWidget: DashboardWidget = {
                                        ...this.widget,
                                        component: 'sichtbarkeitsindex',
                                        headline: getHeadline(
                                            'sichtbarkeitsindex',
                                            this.getWebsite()
                                        ),
                                    };
                                    newWidget.data = {
                                        domain: this.websites[0].url,
                                    };
                                    this.dashboardService.changeWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,

                                        newWidget
                                    );
                                },
                            },

                            {
                                label: 'Webseiten-Sichtbarkeits-Verlauf',
                                icon: 'pi pi-fw pi-chart-line',
                                tooltip: 'dashboard,website',
                                disabled:
                                    this.widget?.component ===
                                    'sichtbarkeitsindexchart',
                                command: () => {
                                    this.dashboardService.changeWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            ...this.widget,
                                            component:
                                                'sichtbarkeitsindexchart',
                                            data: {
                                                ...this.widget.data,
                                                ansicht: 'mobile',
                                            },
                                            headline: getHeadline(
                                                'sichtbarkeitsindexchart',
                                                this.getWebsite(),
                                                'mobile'
                                            ),
                                        }
                                    );
                                },
                            },
                            {
                                label: 'Top rankende URL',
                                icon: 'pi pi-fw pi-star',
                                tooltip: 'dashboard,website',

                                disabled:
                                    this.widget?.component ===
                                    'bestRankendeUrl',
                                command: () => {
                                    this.dashboardService.changeWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            ...this.widget,
                                            component: 'bestRankendeUrl',
                                            data: {
                                                website: this.getWebsite(),
                                            },

                                            headline: getHeadline(
                                                'bestRankendeUrl',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                            {
                                label: 'Rankende URLs',
                                icon: 'pi pi-fw pi-list',
                                tooltip: 'dashboard,website',

                                disabled:
                                    this.widget?.component === 'rankendeUrls',
                                command: () => {
                                    this.dashboardService.changeWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            ...this.widget,
                                            component: 'rankendeUrls',
                                            data: {
                                                website: this.getWebsite(),
                                            },
                                            headline: getHeadline(
                                                'rankendeUrls',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                        ],
                    },
                    {
                        label: 'Optimierungen',
                        tooltip: 'dashboard,website',
                        items: [
                            {
                                label: 'PageSpeed Insights',
                                icon: 'pi pi-fw pi-clock',
                                tooltip: 'dashboard,website',
                                disabled:
                                    this.widget?.component === 'pagespeed',
                                command: () => {
                                    this.dashboardService.changeWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            ...this.widget,
                                            component: 'pagespeed',
                                            data: {
                                                website: this.getWebsite(),
                                            },
                                            headline: getHeadline(
                                                'pagespeed',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                            {
                                label: 'OnPage Fehler & Warnungen',
                                icon: 'pi pi-fw pi-times-circle',
                                tooltip: 'dashboard,website',
                                disabled:
                                    this.widget?.component ===
                                    'onPageAnalytics',
                                command: () => {
                                    this.dashboardService.changeWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            ...this.widget,
                                            component: 'onPageAnalytics',
                                            data: {
                                                website: this.getWebsite(),
                                            },
                                            headline: getHeadline(
                                                'onPageAnalytics',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                        ],
                    },
                    {
                        label: 'Traffic',
                        items: [
                            {
                                label: 'Webseiten Traffic',
                                icon: 'pi pi-fw pi-users',
                                tooltip: 'dashboard,website',

                                disabled:
                                    this.widget?.component === 'websiteTraffic',
                                command: () => {
                                    this.dashboardService.changeWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            ...this.widget,
                                            component: 'websiteTraffic',
                                            data: {
                                                website: this.getWebsite(),
                                            },
                                            headline: getHeadline(
                                                'websiteTraffic',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                            {
                                label: 'Zugriffsquellen',
                                icon: 'pi pi-fw pi-sign-in',
                                tooltip: 'dashboard,website',
                                disabled:
                                    this.widget?.component === 'channeltype',
                                command: () => {
                                    this.dashboardService.changeWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            ...this.widget,
                                            component: 'channeltype',
                                            data: {
                                                website: this.getWebsite(),
                                            },
                                            headline: getHeadline(
                                                'channeltype',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                            {
                                label: 'Webseiten-Besuche im Verlauf',
                                icon: 'pi pi-fw pi-chart-bar',
                                tooltip: 'dashboard,website',
                                disabled:
                                    this.widget?.component ===
                                    'visitsByWebsite',
                                command: () => {
                                    this.dashboardService.changeWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            ...this.widget,
                                            component: 'visitsByWebsite',
                                            data: {
                                                ...this.widget.data,
                                            },
                                            headline: getHeadline(
                                                'visitsByWebsite',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                            {
                                label: 'Einzelne Webseiten-Besucher im Verlauf',
                                icon: 'pi pi-fw pi-chart-line',
                                tooltip: 'dashboard,website',
                                disabled:
                                    this.widget?.component ===
                                    'uniqueVisitorsByWebsite',
                                command: () => {
                                    this.dashboardService.changeWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            ...this.widget,
                                            component:
                                                'uniqueVisitorsByWebsite',
                                            data: {
                                                ...this.widget.data,
                                            },
                                            headline: getHeadline(
                                                'uniqueVisitorsByWebsite',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                            
                            {
                                label: 'Klicks und Impressionen Übersicht',
                                icon: 'pi pi-fw pi-search',
                                tooltip: 'dashboard,website',
                                disabled: this.widget?.component === 'gscTotal',
                                command: () => {
                                    this.dashboardService.changeWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            ...this.widget,
                                            component: 'gscTotal',
                                            data: {
                                                website: this.getWebsite(),
                                            },
                                            headline: getHeadline(
                                                'gscTotal',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },

                            {
                                label: 'Klicks und Impressionen im Verlauf',
                                icon: 'pi pi-fw pi-chart-line',
                                tooltip: 'dashboard,website',
                                disabled:
                                    this.widget?.component ===
                                    'gscImpressionCtrClickChart',
                                command: () => {
                                    this.dashboardService.changeWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            ...this.widget,
                                            component:
                                                'gscImpressionCtrClickChart',
                                            data: {
                                                website: this.getWebsite(),
                                            },
                                            headline: getHeadline(
                                                'gscImpressionCtrClickChart',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                        ],
                    },
                    {
                        label: 'Seitenstatistiken',
                        tooltip: 'dashboard,website',
                        items: [
                            {
                                label: 'Einstiegsseitenstatistik Titel',
                                icon: 'pi pi-fw pi-list',
                                tooltip: 'dashboard,website',

                                disabled:
                                    this.widget?.component ===
                                    'einstiegsseitenTitle',
                                command: () => {
                                    this.dashboardService.changeWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            ...this.widget,
                                            component: 'einstiegsseitenTitle',
                                            data: {
                                                ...this.widget.data,
                                                website: this.getWebsite(),
                                                method: 'getEntryPageTitles',
                                            },
                                            headline: getHeadline(
                                                'einstiegsseitenTitle',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                            {
                                label: 'Einstiegsseitenstatistik URL',
                                icon: 'pi pi-fw pi-list',
                                tooltip: 'dashboard,website',

                                disabled:
                                    this.widget?.component ===
                                    'einstiegsseitenUrl',
                                command: () => {
                                    this.dashboardService.changeWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            ...this.widget,
                                            component: 'einstiegsseitenUrl',
                                            data: {
                                                ...this.widget.data,
                                                website: this.getWebsite(),
                                                method: 'getEntryPageUrls',
                                            },
                                            headline: getHeadline(
                                                'einstiegsseitenUrl',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                            {
                                label: 'Ausstiegsseitenstatistik Titel',
                                icon: 'pi pi-fw pi-list',
                                tooltip: 'dashboard,website',

                                disabled:
                                    this.widget?.component ===
                                    'ausstiegsseitenTitle',
                                command: () => {
                                    this.dashboardService.changeWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            ...this.widget,
                                            component: 'ausstiegsseitenTitle',
                                            data: {
                                                ...this.widget.data,
                                                website: this.getWebsite(),
                                                method: 'getExitPageTitles',
                                            },
                                            headline: getHeadline(
                                                'ausstiegsseitenTitle',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                            {
                                label: 'Ausstiegsseitenstatistik URL',
                                icon: 'pi pi-fw pi-list',
                                tooltip: 'dashboard,website',

                                disabled:
                                    this.widget?.component ===
                                    'ausstiegsseitenUrl',
                                command: () => {
                                    this.dashboardService.changeWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            ...this.widget,
                                            component: 'ausstiegsseitenUrl',
                                            data: {
                                                ...this.widget.data,
                                                website: this.getWebsite(),
                                                method: 'getExitPageUrls',
                                            },
                                            headline: getHeadline(
                                                'ausstiegsseitenUrl',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                            {
                                label: 'Downloads',
                                icon: 'pi pi-fw pi-list',
                                tooltip: 'dashboard,website',

                                disabled:
                                    this.widget?.component === 'downloads',
                                command: () => {
                                    this.dashboardService.changeWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            ...this.widget,
                                            component: 'downloads',
                                            data: {
                                                website: this.getWebsite(),
                                                ...this.widget.data,
                                                method: 'getDownloads',
                                            },
                                            headline: getHeadline(
                                                'downloads',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                            {
                                label: 'Seitenstatistik Titel',
                                icon: 'pi pi-fw pi-list',
                                tooltip: 'dashboard,website',

                                disabled:
                                    this.widget?.component === 'seitenTitle',
                                command: () => {
                                    this.dashboardService.changeWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            ...this.widget,
                                            component: 'seitenTitle',
                                            data: {
                                                ...this.widget.data,
                                                website: this.getWebsite(),
                                                method: 'getPageTitles',
                                            },
                                            headline: getHeadline(
                                                'seitenTitle',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                            {
                                label: 'Seitenstatistik URL',
                                icon: 'pi pi-fw pi-list',
                                tooltip: 'dashboard,website',

                                disabled:
                                    this.widget?.component === 'seitenUrl',
                                command: () => {
                                    this.dashboardService.changeWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            ...this.widget,
                                            component: 'seitenUrl',
                                            data: {
                                                ...this.widget.data,
                                                website: this.getWebsite(),
                                                method: 'getPageUrls',
                                            },
                                            headline: getHeadline(
                                                'seitenUrl',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                        ],
                    },
                    {
                        label: 'Sonstiges',
                        tooltip: 'dashboard,website',
                        items: [
                            {
                                label: 'Ermittelte Wettbewerber',
                                icon: 'pi pi-fw pi-users',
                                tooltip: 'dashboard,website',
                                disabled:
                                    this.widget?.component ===
                                    'wettbewerberRanking',
                                command: () => {
                                    this.dashboardService.changeWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            ...this.widget,
                                            component: 'wettbewerberRanking',
                                            data: {
                                                website: this.getWebsite(),
                                            },
                                            headline: getHeadline(
                                                'wettbewerberRanking',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },

                        ],
                    },
                ],
            },
        ];
        // Components, welche über Optionen bearbeitet werden sollen können, hier einfügen
        if (
            this.widget.component === 'sichtbarkeitsindex' ||
            this.widget.component === 'keywordRanking' ||
            this.widget.component === 'KeywordSichtbarkeitsChart' ||
            this.widget.component === 'pagespeed' ||
            this.widget.component === 'keywordChance' ||
            this.widget.component === 'keywordIdeas' ||
            this.widget.component === 'wettbewerberRanking' ||
            this.widget.component === 'rankendeUrls' ||
            this.widget.component === 'bestRankendeUrl' ||
            this.widget.component === 'sichtbarkeitsindexchart' ||
            this.widget.component === 'websiteTraffic' ||
            this.widget.component === 'channeltype' ||
            this.widget.component === 'ausstiegsseitenTitle' ||
            this.widget.component === 'einstiegsseitenTitle' ||
            this.widget.component === 'seitenTitle' ||
            this.widget.component === 'einstiegsseitenUrl' ||
            this.widget.component === 'ausstiegsseitenUrl' ||
            this.widget.component === 'seitenUrl' ||
            this.widget.component === 'onPageAnalytics' ||
            this.widget.component === 'downloads' ||
            this.widget.component === 'gscKeywordsTable' ||
            this.widget.component === 'gscImpressionCtrClickChart' ||
            this.widget.component === 'gscTotal'
        ) {
            this.menu = [
                initMenu[0],
                {
                    label: 'Optionen',
                    icon: 'pi pi-fw pi-cog',
                    tooltip: 'dashboard,website',
                    items: [],
                },
            ];
            // Components hier einfügen, welche webseitenspezifisch sind
            if (
                this.widget.component === 'sichtbarkeitsindex' ||
                this.widget.component === 'pagespeed' ||
                this.widget.component === 'websiteTraffic' ||
                this.widget.component === 'onPageAnalytics' ||
                this.widget.component === 'channeltype' ||
                this.widget.component === 'KeywordSichtbarkeitsChart' ||
                this.widget.component === 'bestRankendeUrl' ||
                this.widget.component === 'rankendeUrls' ||
                this.widget.component === 'keywordChance' ||
                this.widget.component === 'keywordIdeas' ||
                this.widget.component === 'keywordRanking' ||
                this.widget.component === 'wettbewerberRanking' ||
                this.widget.component === 'ausstiegsseitenTitle' ||
                this.widget.component === 'ausstiegsseitenUrl' ||
                this.widget.component === 'einstiegsseitenTitle' ||
                this.widget.component === 'einstiegsseitenUrl' ||
                this.widget.component === 'seitenTitle' ||
                this.widget.component === 'seitenUrl' ||
                this.widget.component === 'downloads' ||
                this.widget.component === 'gscKeywordsTable' ||
                this.widget.component === 'gscImpressionCtrClickChart' ||
                this.widget.component === 'gscTotal'
            ) {
                this.menu[1].items!.push({
                    label: 'Webseite wechseln',
                    tooltip: 'dashboard',
                    items: this.websites.map((website) => {
                        return {
                            label: website.name,
                            icon: 'pi pi-fw pi-globe',
                            tooltip: 'dashboard',
                            disabled:
                                website?.id === this.widget.data?.website.id ||
                                this.widget.data?.website.domain ===
                                    website?.url,
                            command: () => {
                                let headLine = getHeadline(
                                    this.widget.component,
                                    website
                                );
                                const newWidget = {
                                    ...this.widget,
                                };
                                newWidget.data.domain = website.url;
                                newWidget.data.website = {
                                    ...website,
                                    sichtbarkeitsindexVerlauf: [],
                                };
                                this.dashboardService.changeWidget(
                                    this.website?.slug || 'overview',
                                    this.dashboardName,
                                    {
                                        ...newWidget,
                                        headline: headLine,
                                    }
                                );
                            },
                        };
                    }),
                });
            }
            // Components hier einfügen, welche für verschiedene Engerätarten verwendet werden können
            if (this.widget.component === 'sichtbarkeitsindexchart') {
                this.menu[1].items!.push({
                    label: 'Endgerät-Art wechseln',
                    tooltip: 'dashboard,website',
                    items: [
                        {
                            label: 'Mobil',
                            icon: 'pi pi-fw pi-mobile',
                            tooltip: 'dashboard,website',
                            disabled: this.widget.data?.ansicht === 'mobile',
                            command: () => {
                                let headLine = getHeadline(
                                    this.widget.component,
                                    this.widget.data?.website,
                                    'mobile'
                                );
                                const newWidget: DashboardWidget = {
                                    ...this.widget,
                                    data: {
                                        ...this.widget.data,
                                        ansicht: 'mobile',
                                    },
                                };

                                this.dashboardService.changeWidget(
                                    this.website?.slug || 'overview',
                                    this.dashboardName,
                                    {
                                        ...newWidget,
                                        headline: headLine,
                                    }
                                );
                            },
                        },
                        {
                            label: 'Desktop',
                            icon: 'pi pi-fw pi-desktop',
                            tooltip: 'dashboard,website',
                            disabled: this.widget.data?.ansicht === 'desktop',
                            command: () => {
                                let headLine = getHeadline(
                                    this.widget.component,
                                    this.widget.data?.website,
                                    'desktop'
                                );
                                const newWidget: DashboardWidget = {
                                    ...this.widget,
                                    data: {
                                        ...this.widget.data,
                                        ansicht: 'desktop',
                                    },
                                };

                                this.dashboardService.changeWidget(
                                    this.website?.slug || 'overview',
                                    this.dashboardName,
                                    {
                                        ...newWidget,
                                        headline: headLine,
                                    }
                                );
                            },
                        },
                    ],
                });
            }
        } else {
            this.menu = initMenu;
        }

        /* hier müssen die Widgets eingefügt werden, welche hinzugefügt werden sollen können im Widget-Menü */
        this.menu = [
            ...this.menu,
            {
                label: 'Widget hinzufügen',
                icon: 'pi pi-fw pi-plus',
                items: [
                    {
                        label: 'Keywords',
                        tooltip: 'dashboard,website',

                        items: [
                            {
                                label: 'Keyword Traffic & Platzierung',
                                tooltip: 'dashboard,website',
                                icon: 'pi pi-fw pi-sort-alt',
                                command: () => {
                                    this.dashboardService.addWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            cols: 1,
                                            rows: 3,
                                            x: 0,
                                            y: 0,
                                            id: findNextFreeNumber(
                                                'id',
                                                this.allWidgets
                                            ),
                                            component: 'keywordRanking',
                                            data: {
                                                website: this.getWebsite(),
                                            },
                                            headline: getHeadline(
                                                'keywordRanking',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                            {
                                label: 'Keyword-Platzierungs-Verlauf',
                                icon: 'pi pi-fw pi-chart-line',
                                tooltip: 'dashboard,website',
                                command: () => {
                                    this.dashboardService.addWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            cols: 3,
                                            rows: 3,
                                            x: 0,
                                            y: 0,
                                            id: findNextFreeNumber(
                                                'id',
                                                this.allWidgets
                                            ),
                                            component:
                                                'KeywordSichtbarkeitsChart',
                                            data: {
                                                website: this.getWebsite(),
                                            },
                                            headline: getHeadline(
                                                'KeywordSichtbarkeitsChart',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                            {
                                label: 'Keyword Trend Analyse',
                                tooltip: 'dashboard,website',
                                icon: 'pi pi-fw pi-arrow-up-right',
                                command: () => {
                                    this.dashboardService.addWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            cols: 3,
                                            rows: 2,
                                            x: 0,
                                            y: 0,
                                            id: findNextFreeNumber(
                                                'id',
                                                this.allWidgets
                                            ),
                                            data: {
                                                domain: this.website
                                                    ? this.website.url
                                                    : null,
                                            },
                                            component:
                                                'trafficUndPositionTrends',
                                            headline: getHeadline(
                                                'trafficUndPositionTrends',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                            {
                                label: 'Keywords mit höchsten Traffic',
                                icon: 'pi pi-fw pi-star',
                                tooltip: 'dashboard',
                                command: () => {
                                    this.dashboardService.addWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            cols: 1,
                                            rows: 3,
                                            x: 0,
                                            y: 0,
                                            id: findNextFreeNumber(
                                                'id',
                                                this.allWidgets
                                            ),
                                            component: 'keywordtraffic',
                                            data: {},
                                            headline:
                                                getHeadline('keywordtraffic'),
                                        }
                                    );
                                },
                            },
                            {
                                label: 'Keyword-Chancen',
                                icon: 'pi pi-fw pi-caret-up',
                                tooltip: 'dashboard,website',

                                disabled:
                                    this.widget?.component === 'keywordChance',
                                command: () => {
                                    this.dashboardService.changeWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            ...this.widget,
                                            component: 'keywordChance',
                                            data: {
                                                website: this.getWebsite(),
                                            },
                                            headline: getHeadline(
                                                'keywordChance',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                            {
                                label: 'Keyword-Ideen',
                                icon: 'pi pi-fw pi-info',
                                tooltip: 'dashboard,website',
                                command: () => {
                                    this.dashboardService.addWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            cols: 1,
                                            rows: 3,
                                            x: 0,
                                            y: 0,
                                            id: findNextFreeNumber(
                                                'id',
                                                this.allWidgets
                                            ),
                                            component: 'keywordIdeas',
                                            data: {
                                                website: this.getWebsite(),
                                            },
                                            headline: getHeadline(
                                                'keywordIdeas',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                            {
                                label: 'Keyword Klicks und Impressionen',
                                tooltip: 'dashboard,website',
                                icon: 'pi pi-fw pi-search',
                                command: () => {
                                    this.dashboardService.addWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            cols: 1,
                                            rows: 3,
                                            x: 0,
                                            y: 0,
                                            id: findNextFreeNumber(
                                                'id',
                                                this.allWidgets
                                            ),
                                            component: 'gscKeywordsTable',
                                            data: {
                                                website: this.getWebsite(),
                                            },
                                            headline: getHeadline(
                                                'gscKeywordsTable',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                        ],
                    },
                    {
                        label: 'Sichtbarkeit',
                        items: [
                            {
                                label: 'Top Sichtbarkeitsindex',
                                icon: 'pi pi-fw pi-star',
                                tooltip: 'dashboard',
                                command: () => {
                                    this.dashboardService.addWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            cols: 1,
                                            rows: 1,
                                            x: 0,
                                            y: 0,
                                            id: findNextFreeNumber(
                                                'id',
                                                this.allWidgets
                                            ),
                                            component: 'topsichtbarkeitsindex',
                                            data: {},
                                            headline: getHeadline(
                                                'topsichtbarkeitsindex',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                            {
                                label: 'Webseiten Sichtbarkeitsindex',
                                tooltip: 'dashboard,website',
                                icon: 'pi pi-fw pi-eye',
                                command: () => {
                                    this.dashboardService.addWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            cols: 1,
                                            rows: 1,
                                            x: 0,
                                            y: 0,
                                            id: findNextFreeNumber(
                                                'id',
                                                this.allWidgets
                                            ),
                                            data: {
                                                domain: this.website
                                                    ? this.website.url
                                                    : this.websites[0].url,
                                                    website: this.getWebsite(),
                                            },
                                            component: 'sichtbarkeitsindex',
                                            headline: getHeadline(
                                                'sichtbarkeitsindex',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                            {
                                label: 'Webseiten-Sichtbarkeits-Verlauf',
                                icon: 'pi pi-fw pi-chart-line',
                                tooltip: 'dashboard,website',

                                command: () => {
                                    this.dashboardService.addWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            cols: 3,
                                            rows: 3,
                                            x: 0,
                                            y: 0,
                                            id: findNextFreeNumber(
                                                'id',
                                                this.allWidgets
                                            ),
                                            component:
                                                'sichtbarkeitsindexchart',
                                            data: {
                                                ...this.widget.data,
                                                ansicht: 'mobile',
                                            },
                                            headline: getHeadline(
                                                'sichtbarkeitsindexchart',
                                                this.getWebsite(),
                                                'mobile'
                                            ),
                                        }
                                    );
                                },
                            },
                            {
                                label: 'Top rankende URL',
                                icon: 'pi pi-fw pi-star',
                                tooltip: 'dashboard,website',
                                command: () => {
                                    this.dashboardService.addWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,

                                        {
                                            cols: 3,
                                            rows: 3,
                                            x: 0,
                                            y: 0,
                                            id: findNextFreeNumber(
                                                'id',
                                                this.allWidgets
                                            ),
                                            component: 'bestRankendeUrl',
                                            data: {
                                                website: this.getWebsite(),
                                            },
                                            headline: getHeadline(
                                                'bestRankendeUrl',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                            {
                                label: 'Rankende URLs',
                                icon: 'pi pi-fw pi-list',
                                tooltip: 'dashboard,website',
                                command: () => {
                                    this.dashboardService.addWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            cols: 3,
                                            rows: 3,
                                            x: 0,
                                            y: 0,
                                            id: findNextFreeNumber(
                                                'id',
                                                this.allWidgets
                                            ),
                                            component: 'rankendeUrls',
                                            data: {
                                                website: this.getWebsite(),
                                            },
                                            headline: getHeadline(
                                                'rankendeUrls',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                        ],
                    },
                    {
                        label: 'Optimierungen',
                        tooltip: 'dashboard,website',

                        items: [
                            {
                                label: 'PageSpeed Insights',
                                icon: 'pi pi-fw pi-clock',
                                tooltip: 'dashboard,website',
                                command: () => {
                                    this.dashboardService.addWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            cols: 2,
                                            rows: 2,
                                            minItemRows: 2,
                                            minItemCols: 2,
                                            x: 0,
                                            y: 0,
                                            id: findNextFreeNumber(
                                                'id',
                                                this.allWidgets
                                            ),
                                            component: 'pagespeed',
                                            data: {
                                                website: this.getWebsite(),
                                            },
                                            headline: getHeadline(
                                                'pagespeed',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                            {
                                label: 'OnPage Fehler & Warnungen',
                                icon: 'pi pi-fw pi-times-circle',
                                tooltip: 'dashboard,website',
                                command: () => {
                                    this.dashboardService.addWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            cols: 2,
                                            rows: 2,
                                            minItemRows: 2,
                                            minItemCols: 2,
                                            x: 0,
                                            y: 0,
                                            id: findNextFreeNumber(
                                                'id',
                                                this.allWidgets
                                            ),
                                            component: 'onPageAnalytics',
                                            data: {
                                                website: this.getWebsite(),
                                            },
                                            headline:
                                                'OnPage Analyse von ' +
                                                (this.website
                                                    ? this.website?.name!
                                                    : this.websites[0].name),
                                        }
                                    );
                                },
                            },
                        ],
                    },
                    {
                        label: 'Traffic',
                        items: [
                            {
                                label: 'Webseiten Traffic',
                                icon: 'pi pi-fw pi-users',
                                tooltip: 'dashboard,website',
                                command: () => {
                                    this.dashboardService.addWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            cols: 1,
                                            rows: 1,
                                            x: 0,
                                            y: 0,
                                            id: findNextFreeNumber(
                                                'id',
                                                this.allWidgets
                                            ),
                                            component: 'websiteTraffic',
                                            data: {
                                                website:this.getWebsite(),
                                            },
                                            headline: getHeadline(
                                                'websiteTraffic',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                            {
                                label: 'Zugriffsquellen',
                                icon: 'pi pi-fw pi-sign-in',
                                tooltip: 'dashboard,website',
                                command: () => {
                                    this.dashboardService.addWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            cols: 2,
                                            rows: 1,
                                            x: 0,
                                            y: 0,
                                            id: findNextFreeNumber(
                                                'id',
                                                this.allWidgets
                                            ),
                                            component: 'channeltype',
                                            data: {
                                                website: this.getWebsite(),
                                            },
                                            headline: getHeadline(
                                                'channeltype',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                            {
                                label: 'Webseiten-Besuche im Verlauf',
                                icon: 'pi pi-fw pi-chart-bar',
                                tooltip: 'dashboard,website',

                                command: () => {
                                    this.dashboardService.addWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            cols: 3,
                                            rows: 3,
                                            x: 0,
                                            y: 0,
                                            id: findNextFreeNumber(
                                                'id',
                                                this.allWidgets
                                            ),
                                            component: 'visitsByWebsite',
                                            data: {
                                                ...this.widget.data,
                                            },
                                            headline: getHeadline(
                                                'visitsByWebsite',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                            {
                                label: 'Einzelne Webseiten-Besucher im Verlauf',
                                icon: 'pi pi-fw pi-chart-line',
                                tooltip: 'dashboard,website',

                                command: () => {
                                    this.dashboardService.addWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            cols: 3,
                                            rows: 3,
                                            x: 0,
                                            y: 0,
                                            id: findNextFreeNumber(
                                                'id',
                                                this.allWidgets
                                            ),
                                            component:
                                                'uniqueVisitorsByWebsite',
                                            data: {
                                                ...this.widget.data,
                                            },
                                            headline: getHeadline(
                                                'uniqueVisitorsByWebsite',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                            
                            {
                                label: 'Klicks und Impressionen Übersicht',
                                tooltip: 'dashboard,website',
                                icon: 'pi pi-fw pi-search',
                                command: () => {
                                    this.dashboardService.addWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            cols: 1,
                                            rows: 1,
                                            x: 0,
                                            y: 0,
                                            id: findNextFreeNumber(
                                                'id',
                                                this.allWidgets
                                            ),
                                            component: 'gscTotal',
                                            data: {
                                                website: this.getWebsite(),
                                            },
                                            headline: getHeadline(
                                                'gscTotal',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },

                            {
                                label: 'Klicks und Impressionen im Verlauf',
                                tooltip: 'dashboard,website',
                                icon: 'pi pi-fw pi-chart-line',
                                command: () => {
                                    this.dashboardService.addWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            cols: 2,
                                            rows: 3,
                                            x: 0,
                                            y: 0,
                                            id: findNextFreeNumber(
                                                'id',
                                                this.allWidgets
                                            ),
                                            component:
                                                'gscImpressionCtrClickChart',
                                            data: {
                                                website: this.getWebsite(),
                                            },
                                            headline: getHeadline(
                                                'gscImpressionCtrClickChart',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                        ],
                    },
                    {
                        label: 'Seitenstatistiken',
                        items: [
                            {
                                label: 'Einstiegsseitenstatistik Titel',
                                icon: 'pi pi-fw pi-list',
                                tooltip: 'dashboard,website',
                                command: () => {
                                    this.dashboardService.addWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            cols: 2,
                                            rows: 3,
                                            x: 0,
                                            y: 0,
                                            id: findNextFreeNumber(
                                                'id',
                                                this.allWidgets
                                            ),
                                            component: 'einstiegsseitenTitle',
                                            data: {
                                                website: this.getWebsite(),
                                                method: 'getEntryPageTitles',
                                            },
                                            headline: getHeadline(
                                                'einstiegsseitenTitle',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                            {
                                label: 'Einstiegsseitenstatistik URL',
                                icon: 'pi pi-fw pi-list',
                                tooltip: 'dashboard,website',
                                command: () => {
                                    this.dashboardService.addWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            cols: 2,
                                            rows: 3,
                                            x: 0,
                                            y: 0,
                                            id: findNextFreeNumber(
                                                'id',
                                                this.allWidgets
                                            ),
                                            component: 'einstiegsseitenUrl',
                                            data: {
                                                website: this.getWebsite(),
                                                method: 'getEntryPageUrls',
                                            },
                                            headline: getHeadline(
                                                'einstiegsseitenUrl',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                            {
                                label: 'Ausstiegsseitenstatistik Titel',
                                icon: 'pi pi-fw pi-list',
                                tooltip: 'dashboard,website',
                                command: () => {
                                    this.dashboardService.addWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            cols: 2,
                                            rows: 3,
                                            x: 0,
                                            y: 0,
                                            id: findNextFreeNumber(
                                                'id',
                                                this.allWidgets
                                            ),
                                            component: 'ausstiegsseitenTitle',
                                            data: {
                                                website: this.getWebsite(),
                                                method: 'getExitPageTitles',
                                            },
                                            headline: getHeadline(
                                                'ausstiegsseitenTitle',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                            {
                                label: 'Ausstiegsseitenstatistik URL',
                                icon: 'pi pi-fw pi-list',
                                tooltip: 'dashboard,website',
                                command: () => {
                                    this.dashboardService.addWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            cols: 2,
                                            rows: 3,
                                            x: 0,
                                            y: 0,
                                            id: findNextFreeNumber(
                                                'id',
                                                this.allWidgets
                                            ),
                                            component: 'ausstiegsseitenUrl',
                                            data: {
                                                website: this.getWebsite(),
                                                method: 'getExitPageUrls',
                                            },
                                            headline: getHeadline(
                                                'ausstiegsseitenUrl',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                            {
                                label: 'Downloads',
                                icon: 'pi pi-fw pi-list',
                                tooltip: 'dashboard,website',
                                command: () => {
                                    this.dashboardService.addWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            cols: 2,
                                            rows: 3,
                                            x: 0,
                                            y: 0,
                                            id: findNextFreeNumber(
                                                'id',
                                                this.allWidgets
                                            ),
                                            component: 'downloads',
                                            data: {
                                                website: this.getWebsite(),
                                                method: 'getDownloads',
                                            },
                                            headline: getHeadline(
                                                'downloads',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                            {
                                label: 'Seitenstatistik Titel',
                                icon: 'pi pi-fw pi-list',
                                tooltip: 'dashboard,website',
                                command: () => {
                                    this.dashboardService.addWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            cols: 2,
                                            rows: 3,
                                            x: 0,
                                            y: 0,
                                            id: findNextFreeNumber(
                                                'id',
                                                this.allWidgets
                                            ),
                                            component: 'seitenTitle',
                                            data: {
                                                website: this.getWebsite(),
                                                method: 'getPageTitles',
                                            },
                                            headline: getHeadline(
                                                'seitenTitle',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                            {
                                label: 'Seitenstatistik URL',
                                icon: 'pi pi-fw pi-list',
                                tooltip: 'dashboard,website',
                                command: () => {
                                    this.dashboardService.addWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            cols: 2,
                                            rows: 3,
                                            x: 0,
                                            y: 0,
                                            id: findNextFreeNumber(
                                                'id',
                                                this.allWidgets
                                            ),
                                            component: 'seitenUrl',
                                            data: {
                                                website: this.getWebsite(),
                                                method: 'getPageUrls',
                                            },
                                            headline: getHeadline(
                                                'seitenUrl',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },
                        ],
                    },
                    {
                        label: 'Sonstiges',
                        tooltip: 'dashboard,website',

                        items: [
                            {
                                label: 'Ermittelte Wettbewerber',
                                tooltip: 'dashboard,website',
                                icon: 'pi pi-fw pi-users',
                                command: () => {
                                    this.dashboardService.addWidget(
                                        this.website?.slug || 'overview',
                                        this.dashboardName,
                                        {
                                            cols: 1,
                                            rows: 3,
                                            x: 0,
                                            y: 0,
                                            id: findNextFreeNumber(
                                                'id',
                                                this.allWidgets
                                            ),
                                            component: 'wettbewerberRanking',
                                            data: {
                                                website: this.getWebsite(),
                                            },
                                            headline: getHeadline(
                                                'wettbewerberRanking',
                                                this.getWebsite()
                                            ),
                                        }
                                    );
                                },
                            },

                        ],
                    },
                ],
            },
            {
                label: 'Widget entfernen',
                tooltip: 'dashboard,website',
                icon: 'pi pi-fw pi-trash',
                command: () => {
                    this.dashboardService.removeWidget(
                        this.website?.slug || 'overview',
                        this.dashboardName,
                        this.widget
                    );
                },
            },
        ];
        this.menu = this.filterItemsByTooltip(this.menu, this.dashboardType);
        if (!this.menu[1].items) {
            this.menu = this.menu.filter((item) => item.label !== 'Optionen');
        }
    }

    /**
     * Fitert die Menüitems nach dem Tooltip und gibt die gefilterten Items zurück
     * @param menuItems 
     * @param tooltipFilter 
     * @returns 
     */
    filterItemsByTooltip(
        menuItems: MenuItem[],
        tooltipFilter: string
    ): MenuItem[] {
        const filteredItems: MenuItem[] = [];
        if (!menuItems) return filteredItems;
        menuItems.forEach((menuItem) => {
            if (menuItem.tooltip?.includes(tooltipFilter)) {
                filteredItems.push({
                    ...menuItem,
                    items: this.filterItemsByTooltip(
                        menuItem.items!,
                        tooltipFilter
                    ),
                });
            } else {
                const filteredSubItems = this.filterItemsByTooltip(
                    menuItem.items!,
                    tooltipFilter
                );
                if (filteredSubItems.length > 0) {
                    filteredItems.push({
                        ...menuItem,
                        items: filteredSubItems,
                    });
                }
            }
        });
        this.setItemsToUndefined(filteredItems);

        return filteredItems;
    }

    /* gibt die aktuelle Webseite des Widgets zurück */
    getWebsite(): Website {
        const website = this.website ? this.website : this.websites[0];
        return {
            ...website,
            sichtbarkeitsindexVerlauf: undefined,
        };
    }

    /* gibt die Farbe zu einer URL zurück */
    getColor() {
        return this.widgetWebsite
            ? generateColorFromString(this.widgetWebsite.url)
            : '';
    }

    setItemsToUndefined(menuItems: MenuItem[]): void {
        if (!menuItems || menuItems.length === 0) {
            return;
        }

        menuItems.forEach((menuItem) => {
            if (menuItem.items && menuItem.items.length === 0) {
                menuItem.items = undefined;
            } else {
                this.setItemsToUndefined(menuItem.items || []);
            }
        });
    }

    onEvent(event: any) {
        event.stopPropagation();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.description =
            descriptions.find((d) => d.component === this.widget.component)
                ?.description || 'Keine Beschreibung vorhanden';

        this.infoHeader =
            descriptions.find((d) => d.component === this.widget.component)
                ?.headline || 'Keine Beschreibung vorhanden';

        this.buildMenu();
    }
}

/* gibt variabel die Überschriften zu den Widgets zurück */
export const getHeadline = (
    component: dashboardComponents,
    website?: Website,
    ansicht?: string
) => {
    let headLine = '';
    const websiteName = website ? website.name : '';
    if (component === 'sichtbarkeitsindex') {
        headLine = 'Sichtbarkeitsindex von ' + websiteName;
    } else if (component === 'keywordRanking') {
        headLine = 'Keyword-Traffic und Platzierung von ' + websiteName;
    } else if (component === 'pagespeed') {
        headLine = 'PageSpeed Insights Ergebnisse von ' + websiteName;
    } else if (component === 'KeywordSichtbarkeitsChart') {
        headLine = 'Keyword Platzierungen im Verlauf von ' + websiteName;
    } else if (component === 'keywordChance') {
        headLine = 'Keyword Chance von ' + websiteName;
    } else if (component === 'keywordIdeas') {
        headLine = 'Keyword Ideen für ' + websiteName;
    } else if (component === 'rankendeUrls') {
        headLine = 'Rankende URLs von ' + websiteName;
    } else if (component === 'bestRankendeUrl') {
        headLine = 'Am besten rankende URL von ' + websiteName;
    } else if (component === 'wettbewerberRanking') {
        headLine = 'Ermittelte Wettbewerber von ' + websiteName;
    } else if (component === 'onPageAnalytics') {
        headLine = 'OnPage Analyse von ' + websiteName;
    } else if (component === 'topsichtbarkeitsindex') {
        headLine = 'Top Sichtbarkeitsindex';
    } else if (component === 'sichtbarkeitsindexchart') {
        headLine =
            ansicht === 'mobile'
                ? 'Sichtbarkeitsindex im Verlauf (Mobil)'
                : 'Sichtbarkeitsindex im Verlauf (Desktop)';
    } else if (component === 'keywordtraffic') {
        headLine = 'Keywords inkl. Traffic';
    } else if (component === 'ausstiegsseitenTitle') {
        headLine = 'Top Ausstiegsseiten mit Titel von ' + websiteName;
    } else if (component === 'visitsByWebsite') {
        headLine = 'Besuche pro Webseite im Verlauf';
    } else if (component === 'einstiegsseitenTitle') {
        headLine = 'Top Landingpages mit Titel von ' + websiteName;
    } else if (component === 'seitenTitle') {
        headLine = 'Seitenstatistik mit Titel von ' + websiteName;
    } else if (component === 'seitenUrl') {
        headLine = 'Seitenstatistik mit URL von ' + websiteName;
    } else if (component === 'einstiegsseitenUrl') {
        headLine = 'Top Einstiegsseiten mit URL von ' + websiteName;
    } else if (component === 'ausstiegsseitenUrl') {
        headLine = 'Top Ausstiegsseiten mit URL von ' + websiteName;
    } else if (component === 'websiteTraffic') {
        headLine = 'Webseiten Traffic Statistiken von ' + websiteName;
    } else if (component === 'downloads') {
        headLine = 'Downloads von ' + websiteName;
    } else if (component === 'uniqueVisitorsByWebsite') {
        headLine = 'Einzelne Besucher pro Webseite im Verlauf';
    } else if (component === 'channeltype') {
        headLine =
            'Traffic-Kanäle von ' + websiteName + ' (gemessen pro Woche)';
    } else if (component === 'trafficUndPositionTrends') {
        headLine = 'Traffic und Platzierungs Trends';
    } else if (component === 'gscKeywordsTable') {
        headLine = 'Keywords Klicks und Impressionen von ' + websiteName;
    } else if (component === 'gscImpressionCtrClickChart') {
        headLine = 'Klicks und Impressionen im Verlauf von ' + websiteName;
    } else if (component === 'gscTotal') {
        headLine = 'Klicks und Impressionen Übersicht von ' + websiteName;
    } else {
        headLine = 'Keine Headline Vergeben';
    }

    return headLine;
};
