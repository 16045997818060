import {
    ChangeDetectorRef,
    Component,
    Host,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Output,
    EventEmitter,
    ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MenuService } from './app.menu.service';
import { LayoutService } from './service/app.layout.service';
import { MenuItem } from 'primeng/api';
import { MenuItemWithContextMenu } from './app.menu.component';
import {
    CopyDashboardService,
    ZwischenablageDashboard,
} from '../services/dashboard/copyDashboard.service';
import {
    Dashboards,
    GeneralDashboardService,
    overviewPresets,
    websitePresets,
} from '../services/dashboard/generalDashboard.service';
import { CLIENT_RENEG_LIMIT } from 'tls';
import { WebsiteService } from '../services/settings/website.service';
import { Website } from '../types/websiteDashboard.types';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[app-menuitem]',
    template: `
        <ng-container>
            <div
                *ngIf="root && item.visible !== false"
                class="layout-menuitem-root-text"
            >
                {{ item.label }}
            </div>
            <a
                *ngIf="
                    (!item.routerLink || item.items) && item.visible !== false
                "
                [attr.href]="item.url"
                (click)="itemClick($event)"
                (contextmenu)="setContextMenu($event, item)"
                [ngClass]="item.class"
                [attr.target]="item.target"
                tabindex="0"
                pRipple
            >
                <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
                <span class="layout-menuitem-text">{{ item.label }}</span>
                <i
                    class="pi pi-fw pi-angle-down layout-submenu-toggler"
                    *ngIf="item.items"
                ></i>
            </a>
            <a
                *ngIf="item.routerLink && !item.items && item.visible !== false"
                (click)="itemClick($event)"
                [ngClass]="item.class"
                [routerLink]="item.routerLink"
                routerLinkActive="active-route"
                [routerLinkActiveOptions]="
                    item.routerLinkActiveOptions || {
                        paths: 'exact',
                        queryParams: 'exact',
                        matrixParams: 'ignored',
                        fragment: 'ignored'
                    }
                "
                [queryParams]="{ websiteSlug: 'hallo' }"
                [fragment]="item.fragment"
                [queryParamsHandling]="item.queryParamsHandling"
                [preserveFragment]="item.preserveFragment"
                [skipLocationChange]="item.skipLocationChange"
                [replaceUrl]="item.replaceUrl"
                [state]="item.state"
                [queryParams]="item.queryParams"
                [attr.target]="item.target"
                tabindex="0"
                pRipple
            >
                <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
                <span class="layout-menuitem-text">{{ item.label }}</span>
                <i
                    class="pi pi-fw pi-angle-down layout-submenu-toggler"
                    *ngIf="item.items"
                ></i>
            </a>

            <ul
                *ngIf="item.items && item.visible !== false"
                [@children]="submenuAnimation"
            >
                <ng-template
                    ngFor
                    let-child
                    let-i="index"
                    [ngForOf]="item.items"
                >
                    <li
                        app-menuitem
                        [item]="child"
                        (contextmenu)="setContextMenu($event, item.items[i])"
                        [index]="i"
                        [parentKey]="key"
                        [class]="child.badgeClass"
                    ></li>
                </ng-template>
            </ul>
        </ng-container>
        <p-contextMenu
            #menu
            [model]="contextMenu"
            appendTo="body"
        ></p-contextMenu>
        <p-dialog header="Dashboard benennen" [(visible)]="dialogVisible">
            <div>
                <div class="my-3">
                    Vergeben Sie einen neuen Namen für das Dashboard. Dieser
                    wird dann links in der Übersicht angezeigt.
                    <br />
                    <br />
                    <b
                        >Achtung: Gleiche Namen sorgen für eine Überschreibung
                        des alten Dashboards!</b
                    >
                </div>
                <input
                autofocus
                    type="text"
                    class="w-full mb-3"
                    pInputText
                    [(ngModel)]="presetName"
                />
            </div>
            <div class="flex flex-row-reverse">
                <p-button
                    (click)="dialogVisible = false"
                    label="Abbrechen"
                    class="mr-2"
                ></p-button>
                <button
                    type="button"
                    pButton
                    [disabled]="presetName.length < 3"
                    (click)="onNameSelect(presetName)"
                    label="Speichern"
                    class="mr-2"
                ></button>
            </div>
        </p-dialog>
    `,
    animations: [
        trigger('children', [
            state(
                'collapsed',
                style({
                    height: '0',
                })
            ),
            state(
                'expanded',
                style({
                    height: '*',
                })
            ),
            transition(
                'collapsed <=> expanded',
                animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')
            ),
        ]),
    ],
})
export class AppMenuitemComponent implements OnInit, OnDestroy {
    @ViewChild('menu') menu: any;

    @Input() item: any;

    @Input() index!: number;

    @Input() @HostBinding('class.layout-root-menuitem') root!: boolean;

    @Input() parentKey!: string;

    @Output() onItemRightClick = new EventEmitter<{
        event: Event;
        item: any;
    }>();

    copiedDashboard?: ZwischenablageDashboard;
    dashboards!: Dashboards;

    active = false;
    websites: Website[] = [];

    menuSourceSubscription: Subscription;

    menuResetSubscription: Subscription;

    contextMenu: MenuItem[] = [];

    onNameSelect: (dashboardName: string) => void = () => {};
    presetName: string = '';
    dialogVisible = false;

    setContextMenu(event: Event, item: MenuItemWithContextMenu) {
        event.stopPropagation();
        const e: Event = event;
        console;
        let contextMenu: MenuItem[] = [];

        const presets =
            item.slug === 'overview' ? overviewPresets : websitePresets;

        if (item.parent) {
            contextMenu = [
                {
                    label: 'Dashboard einfügen',
                    icon: 'pi pi-fw pi-file',
                    disabled:
                        this.copiedDashboard === undefined ||
                        (item.slug === 'overview' &&
                            this.copiedDashboard?.type !== 'overview') ||
                        (item.slug !== 'overview' &&
                            this.copiedDashboard?.type === 'overview'),
                    command: () => {
                        this.onNameSelect = () => {
                            this.copyDashboardService.pasteDashboard(
                                item.slug!,
                                this.presetName
                            );
                            this.dialogVisible = false;
                        };
                        this.presetName =
                            this.copiedDashboard?.name! + ' (Kopie)';

                        this.dialogVisible = true;
                    },
                },
                {
                    label: 'Preset hinzufügen',
                    icon: 'pi pi-fw pi-plus',
                    items: presets.map((preset) => {
                        return {
                            label: preset,
                            icon: 'pi pi-fw pi-file',
                            command: () => {
                                this.onNameSelect = (dashboardName: string) => {
                                    this.dashboardService.applyPreset(
                                        item.slug!,
                                        dashboardName,
                                        preset,
                                        this.websites.find(
                                            (website) =>
                                                website.slug === item.slug
                                        )
                                    );
                                    this.dialogVisible = false;
                                };
                                this.presetName = '';
                                this.dialogVisible = true;
                            },
                        };
                    }),
                },
            ];
        } else {
            if (item.slug) {
                contextMenu = [
                    {
                        label: 'Dashboard kopieren',
                        icon: 'pi pi-fw pi-copy',
                        command: () => {
                            this.copyDashboardService.copyDashboard({
                                type:
                                    item.slug === 'overview'
                                        ? 'overview'
                                        : 'website',
                                name: item.label!,
                                dashboard:
                                    this.dashboards[item.slug!][item.label!],
                            });
                        },
                    },
                    {
                        label: 'Dashboard ersetzen',
                        icon: 'pi pi-fw pi-file',
                        disabled:
                            this.copiedDashboard === undefined ||
                            (item.slug === 'overview' &&
                                this.copiedDashboard?.type !== 'overview') ||
                            (item.slug !== 'overview' &&
                                this.copiedDashboard?.type === 'overview'),
                        command: () => {
                            this.copyDashboardService.pasteDashboard(
                                item.slug!,
                                item.label!
                            );
                        },
                    },
                    {
                        separator: true,
                    },
                    {
                        label: 'Dashboard umbenennen',
                        icon: 'pi pi-fw pi-pencil',
                        command: () => {
                            this.onNameSelect = () => {
                                this.dashboardService.renameDashboard(
                                    item.slug!,
                                    item.label!,
                                    this.presetName
                                );
                                this.router.navigate([
                                    '/dashboard',
                                    item.slug,
                                    this.presetName,
                                ]);
                            };
                            this.presetName = '';
                            this.dialogVisible = true;
                        },
                    },
                    {
                        label: 'Dashboard löschen',
                        icon: 'pi pi-fw pi-trash',
                        command: () => {
                            this.dashboardService.deleteDashboard(
                                item.slug!,
                                item.label!
                            );
                        },
                    },
                    {
                        label: 'Dashboard mit Preset ersetzen',
                        icon: 'pi pi-fw pi-plus',
                        items: presets.map((preset) => {
                            return {
                                label: preset,
                                icon: 'pi pi-fw pi-file',
                                command: () => {
                                    this.dashboardService.applyPreset(
                                        item.slug!,
                                        item.label!,
                                        preset,

                                        this.websites.find(
                                            (website) =>
                                                website.slug === item.slug
                                        )
                                    );
                                    this.dialogVisible = false;
                                },
                            };
                        }),
                    },
                ];
            }
        }

        this.contextMenu = contextMenu;
        this.menu.toggle(e);
    }

    key: string = '';

    constructor(
        public layoutService: LayoutService,
        private cd: ChangeDetectorRef,
        public router: Router,
        private menuService: MenuService,
        private dashboardService: GeneralDashboardService,
        private copyDashboardService: CopyDashboardService,
        private websiteService: WebsiteService
    ) {
        this.menuSourceSubscription = this.menuService.menuSource$.subscribe(
            (value) => {
                Promise.resolve(null).then(() => {
                    if (value.routeEvent) {
                        this.active =
                            value.key === this.key ||
                            value.key.startsWith(this.key + '-')
                                ? true
                                : false;
                    } else {
                        if (
                            value.key !== this.key &&
                            !value.key.startsWith(this.key + '-')
                        ) {
                            this.active = false;
                        }
                    }
                });
            }
        );

        this.menuResetSubscription = this.menuService.resetSource$.subscribe(
            () => {
                this.active = false;
            }
        );

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((params) => {
                if (this.item.routerLink) {
                    this.updateActiveStateFromRoute();
                }
            });
    }

    onRightClick(event: Event) {
        event.preventDefault();

        this.onItemRightClick.emit({ event, item: this.item });
    }
    /** Methode, die beim Aufbau der Komponente ausgeführt wird */
    ngOnInit() {
        this.key = this.parentKey
            ? this.parentKey + '-' + this.index
            : String(this.index);

        if (this.item.routerLink) {
            this.updateActiveStateFromRoute();
        }

        this.copyDashboardService.copiedDashboard$.subscribe((value) => {
            this.copiedDashboard = value;
        });

        this.websiteService.websites$.subscribe((value) => {
            this.websites = value;
        });

        this.dashboardService.dashboards$.subscribe((value) => {
            this.dashboards = value;
        });
    }

    updateActiveStateFromRoute() {
        let activeRoute = this.router.isActive(this.item.routerLink[0], {
            paths: 'exact',
            queryParams: 'ignored',
            matrixParams: 'ignored',
            fragment: 'ignored',
        });

        if (activeRoute) {
            this.menuService.onMenuStateChange({
                key: this.key,
                routeEvent: true,
            });
        }
    }

    itemClick(event: Event) {
        // avoid processing disabled items
        if (this.item.disabled) {
            event.preventDefault();
            return;
        }

        // execute command
        if (this.item.command) {
            this.item.command({ originalEvent: event, item: this.item });
        }

        // toggle active state
        if (this.item.items) {
            this.active = !this.active;
        }

        this.menuService.onMenuStateChange({ key: this.key });
    }

    get submenuAnimation() {
        return this.root ? 'expanded' : this.active ? 'expanded' : 'collapsed';
    }

    @HostBinding('class.active-menuitem')
    get activeClass() {
        return this.active && !this.root;
    }
    /** Methode, die beim Löschen der Komponente ausgeführt wird */
    ngOnDestroy() {
        if (this.menuSourceSubscription) {
            this.menuSourceSubscription.unsubscribe();
        }

        if (this.menuResetSubscription) {
            this.menuResetSubscription.unsubscribe();
        }
    }
}
